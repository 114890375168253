import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ResetPasswordService} from '../shared/services/reset-password.service';
import {MessageService} from '../../shared/services/message.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  formGroup: FormGroup;

  loading = false;
  success = false;

  constructor(
    private fb: FormBuilder,
    private resetPasswordService: ResetPasswordService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmit() {
    this.loading = true;

    this.resetPasswordService.requestReset(this.formGroup.value.email).subscribe(() => {
      this.loading = false;
      this.success = true;
      this.messageService.showMessage('Sollte diese E-Mail-Adresse existieren, haben wir dir eine E-Mail gesendet, damit du dein Passwort zurücksetzen kannst!');
    }, error => {
      this.loading = false;
      console.log(error);
      this.messageService.showMessage('Es ist ein Fehler aufgetreten. Leider konnten wir dir keine E-Mail senden.');
    });
  }

}
