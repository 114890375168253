import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserPost} from '../../../shared/models/user-post';
import {MessageService} from '../../../shared/services/message.service';
import {PostService} from '../../shared/services/post.service';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.css']
})
export class ReportDialogComponent implements OnInit {
  form: FormGroup;
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<ReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      post: UserPost,
    },
    private postService: PostService,
    private messageService: MessageService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      reasonType: ['', Validators.required],
      reasonText: '',
    });
  }

  report() {
    this.isLoading = true;
    this.postService.reportPost(this.data.post.id, this.form.value).subscribe(() => {
      this.messageService.showMessage('Wir haben deine Meldung erhalten. Vielen Dank!');
      this.dialogRef.close(true);
    }, () => {
      this.isLoading = false;
      this.messageService.showMessage('Leider ist ein Fehler beim Melden dieses Posts aufgetreten. Bitte versuche es später erneut!');
    });
  }

}
