import {Component, forwardRef, OnInit} from '@angular/core';
import {SkillItem} from './skill-item';
import {SubjectsService} from '../../../shared/services/subjects.service';
import {ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-skills-select',
  templateUrl: './skills-select.component.html',
  styleUrls: ['./skills-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SkillsSelectComponent),
      multi: true,
    }
  ]
})
export class SkillsSelectComponent implements OnInit, ControlValueAccessor {
  subjects: SkillItem[] = [];
  topics: { [subjectId: string]: SkillItem[] } = {};

  selectedSubject?: SkillItem;
  selectedSkills: { topicId: string, ability: number }[] = [];

  private propagateChange = (_: any) => {
  };

  constructor(
    private fb: FormBuilder,
    private subjectsService: SubjectsService,
  ) {
  }

  ngOnInit() {
    this.subjectsService.getSubjects().subscribe(subjects => {
      this.subjects = subjects.map(subject => {
        const item = new SkillItem(subject.id, 'subject', subject.displayName, subject);
        item.colors = subject.colors;
        this.getSubjectTopics(subject);
        return item;
      });
    });
  }

  selectSubject(event) {
    if (this.selectedSubject) {
      this.selectedSubject.selected = false;
      this.selectedSubject.icon = 'arrow_drop_down';
    }
    if (this.selectedSubject === event.item) {
      this.selectedSubject = undefined;
    } else {
      this.selectedSubject = event.item;
      this.selectedSubject.selected = true;
      this.selectedSubject.icon = 'arrow_right';
    }
  }

  getSubjectBackground() {
    return `linear-gradient(45deg, ${this.selectedSubject.colors})`;
  }

  get selectedIds() {
    return this.selectedSkills.map(skill => skill.topicId);
  }

  getSelectedSkills() {
    const topics = [].concat.apply([], Object.values(this.topics));
    const selected = topics.filter(topic => this.selectedIds.includes(topic.id));
    selected.forEach(skill => {
      skill.selected = true;
      skill.icon = 'remove';
    });
    return selected;
  }

  toggleSkill(event) {
    if (this.selectedIds.indexOf(event.item.id) === -1) {
      this.selectedSkills.push({topicId: event.item.id, ability: 0});
      event.item.selected = true;
      event.item.icon = 'remove';
    } else {
      this.removeSkill(event);
    }
    this.propagateChange(this.selectedSkills);
  }

  updateSkill(event) {
    const index = this.selectedIds.indexOf(event.itemId);
    if (index !== -1) {
      this.selectedSkills[index].ability = event.ability;
      this.propagateChange(this.selectedSkills);
    }
  }

  removeSkill(event) {
    const index = this.selectedIds.indexOf(event.item.id);
    if (index !== -1) {
      this.selectedSkills.splice(index, 1);
      event.item.selected = false;
      event.item.icon = 'add';
    }
    this.propagateChange(this.selectedSkills);
  }

  getSubjectTopics(subject) {
    if (this.topics[subject.id]) {
      return;
    }
    this.subjectsService.getSubjectTopics(subject.id)
      .subscribe(topics => {
        this.topics[subject.id] = topics.map(topic => {
          const item = new SkillItem(topic.id, 'skill', topic.name, subject, false, 'add');
          const index = this.selectedIds.indexOf(topic.id);
          if (index !== -1) {
            item.ability = this.selectedSkills[index].ability + 4;
          }
          return item;
        });
      });
  }

  writeValue(obj: any): void {
    this.selectedSkills = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }
}
