import {Component, OnInit} from '@angular/core';
import {UserPost} from '../../shared/models/user-post';
import {PostService} from '../shared/services/post.service';
import {first} from 'rxjs/operators';
import {SubjectsService} from '../../shared/services/subjects.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {of, Subject} from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  searchResults: UserPost[] = [];

  filterOptions = {
    types: {
      id: 'types',
      name: 'Typ',
      type: 'checkboxes',
      items: of([
        {
          id: 'request',
          name: 'Anfrage',
        },
        {
          id: 'offer',
          name: 'Angebot',
        },
        {
          id: 'material',
          name: 'Lernmaterial',
        },
      ]),
    },
    subjects: {
      id: 'subjects',
      name: 'Fach',
      type: 'checkboxes',
      items: new Subject<{ id: string, name: string }[]>(),
    },
    // TODO: Implement sorting by class and date
    // classes: {
    //   id: 'classes',
    //   name: 'Klassenstufe',
    //   type: 'checkboxes',
    //   items: new Subject(),
    // },
    // date: {
    //   id: 'date',
    //   name: 'Datum',
    //   type: 'date-range',
    // },
  };
  form: FormGroup;

  isLoading = false;
  loadTimeout;
  isLimitReached = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private postService: PostService,
    private subjectsService: SubjectsService,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      q: '',
    });

    Object.keys(this.filterOptions).forEach(key => {
      this.form.addControl(key, this.fb.control([]));
    });

    // Lädt bereits existierende Suchkriterien aus den URL-Parametern
    const queryParams = this.route.snapshot.queryParams;
    const defaultFilters = {};
    Object.keys(queryParams).forEach(key => {
      if (key !== 'q' && typeof queryParams[key] === 'string') {
        defaultFilters[key] = [queryParams[key]];
      } else {
        defaultFilters[key] = queryParams[key];
      }
    });

    // Lädt Fächer
    this.subjectsService.getSubjects().subscribe(subjects => {
      this.filterOptions.subjects.items.next(subjects
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
        .map(subject => {
          return {
            id: subject.name,
            name: subject.displayName,
          };
        }));

      this.form.patchValue(defaultFilters);
      this.search(defaultFilters);

      // Triggert neue Suche, sobald Suche geändert wurde
      this.form.valueChanges.subscribe(value => {
        this.isLimitReached = false;
        Object.keys(value).forEach(key => {
          if (value[key].length === 0) {
            delete value[key];
          }
        });
        this.router.navigate(['/posts'], {queryParams: value});

        clearTimeout(this.loadTimeout);

        this.loadTimeout = setTimeout(() => {
          this.search(value);
        }, 250);
      });
    });
  }

  getFilterKeys() {
    return Object.values(this.filterOptions);
  }

  // Sendet Such-Anfrage mit angegebenen Filtern
  search(filters, offset = 0) {
    if (this.isLoading || this.isLimitReached) {
      return;
    }
    this.isLoading = true;
    this.postService.searchPosts(filters, undefined, offset)
      .pipe(first())
      .subscribe(posts => {
        if (offset === 0) {
          this.searchResults = [];
        }
        this.searchResults.push(...posts);
        this.isLoading = false;
        if (posts.length < 10) {
          this.isLimitReached = true;
        }
      });
  }

  onScroll(event) {
    if (event.target.scrollHeight - event.target.scrollTop < event.target.clientHeight + 100) {
      this.search(this.form.value, this.searchResults.length);
    }
  }
}
