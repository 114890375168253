import {Injectable} from '@angular/core';
import applicationConfig from '../../../../config.json';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor() {
  }

  get config() {
    return applicationConfig;
  }
}
