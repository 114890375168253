import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Feedback} from '../models/feedback';
import {AppConfig} from '../../../app-config';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminFeedbackService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getFeedback(sort = 'datetime', desc = true, page = 0): Observable<Feedback[]> {
    return this.http.get<Feedback[]>(`${AppConfig.API_ENDPOINT}/admin/feedback`, {
      params: {
        sort,
        desc: desc.toString(),
        page: page.toString(),
      }
    });
  }

  getCount(): Observable<number> {
    return this.http.get<{ count: number }>(`${AppConfig.API_ENDPOINT}/admin/feedback/count`)
      .pipe(map(count => count.count));
  }

  markAsCompleted(id: string): Observable<void> {
    return this.http.patch<void>(`${AppConfig.API_ENDPOINT}/admin/feedback/${id}/complete`, {});
  }

}
