import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Subject} from '../../../shared/models/subject';
import {AppConfig} from '../../../app-config';
import {HttpClient} from '@angular/common/http';
import {SubjectTopic} from '../../../shared/models/subject-topic';

@Injectable({
  providedIn: 'root'
})
export class AdminSubjectService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getSubjects(): Observable<Subject[]> {
    return this.http.get<Subject[]>(`${AppConfig.API_ENDPOINT}/admin/subjects`);
  }

  getSubject(id: string): Observable<Subject & { topic: SubjectTopic[] }> {
    return this.http.get<Subject & { topic: SubjectTopic[] }>(`${AppConfig.API_ENDPOINT}/admin/subjects/${id}`);
  }

  addSubject(data): Observable<Subject> {
    return this.http.post<Subject>(`${AppConfig.API_ENDPOINT}/admin/subjects`, data);
  }

  updateSubject(id: string, subject): Observable<void> {
    return this.http.patch<void>(`${AppConfig.API_ENDPOINT}/admin/subjects/${id}`, subject);
  }
}
