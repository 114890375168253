import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../../app-config';
import {User} from '../../../shared/models/user';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(
    private http: HttpClient,
  ) {
  }

  checkInvitationCode(invitationCode: string): Observable<{}> {
    return this.http.post(`${AppConfig.API_ENDPOINT}/users/register/invitation`, {invitationCode});
  }

  checkUsername(username: string, invitationCode?: string): Observable<boolean> {
    return this.http.post<boolean>(`${AppConfig.API_ENDPOINT}/users/register/username`, {username, invitationCode});
  }

  checkEmail(email: string, invitationCode?: string): Observable<boolean> {
    return this.http.post<boolean>(`${AppConfig.API_ENDPOINT}/users/register/email`, {email, invitationCode});
  }

  register(user: User): Observable<boolean> {
    return this.http.post<boolean>(`${AppConfig.API_ENDPOINT}/users/register`, user);
  }

  verify(token: string): Observable<void> {
    return this.http.post<void>(`${AppConfig.API_ENDPOINT}/users/verify`, {token});
  }

}
