import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../../app-config';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminReportService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getReports(sort = 'dateCreated', desc = true, page = 0): Observable<[]> {
    return this.http.get<[]>(`${AppConfig.API_ENDPOINT}/admin/reports?sort=${sort}&desc=${desc}&page=${page}`);
  }

  getCount(): Observable<number> {
    return this.http.get<{ count: number }>(`${AppConfig.API_ENDPOINT}/admin/reports/count`)
      .pipe(map(count => count.count));
  }
}
