import {Component, forwardRef, OnInit, ViewChild} from '@angular/core';
import {UploadService} from '../upload.service';
import {Observable, of} from 'rxjs';
import {ConfigService} from '../../shared/services/config.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-upload-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormComponent),
      multi: true,
    }
  ]
})
export class FormComponent implements OnInit, ControlValueAccessor {
  @ViewChild('file', {static: true}) file;
  public files: Set<{
    id?: string,
    name: string,
    type: string,
    size: number,
    progress?: Observable<number>,
    error?: string,
  }> = new Set();

  private propagateChange = (_: any) => {
  };

  constructor(
    private configService: ConfigService,
    private uploadService: UploadService,
  ) {
  }

  ngOnInit() {
  }

  addFiles() {
    this.file.nativeElement.click();
  }

  onFilesAdded() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    this.upload(Object.values(files));
  }

  upload(files: File[]) {
    // Fügt die Daten der hochgeladenen Dateien zum Fortschritt-Objekt hinzu
    files.forEach(file => {
      if (file.size > this.configService.config.posts.attachments.maxSize) {
        this.files.add({
          name: file.name,
          type: file.type,
          size: file.size,
          progress: of(100),
          error: 'Datei ist zu groß.',
        });
        return;
      }

      if (Array.from(this.files).find(existingFile => existingFile.name === file.name)) {
        this.files.add({
          name: file.name,
          type: file.type,
          size: file.size,
          progress: of(100),
          error: 'Es gibt bereits eine Datei mit dem gleichen Namen. Bitte entferne eine der beiden Dateien oder benenne die Datei vor dem Hochladen um.',
        });
        return;
      }

      const result = this.uploadService.upload(file);
      const f: {
        id?: string,
        name: string,
        type: string,
        size: number,
        progress?: Observable<number>,
        error?: string,
      } = Object.assign(file, result);
      f.error = undefined;
      result.error.subscribe(error => {
        switch (error.message) {
          case 'LIMIT_FILE_SIZE':
            f.error = 'Datei ist zu groß.';
            break;
          case 'FORBIDDEN_FILE_TYPE':
            f.error = 'Dateityp ist nicht erlaubt.';
            break;
          case 'SERVER_CONNECTION_FAILED':
            f.error = 'Es konnte keine Verbindung zum Server aufgebaut werden.';
            break;
          default:
            f.error = error.message;
        }
      });
      result.result.subscribe(value => {
        f.id = value.id;
        this.update();
      });
      this.files.add(f);
    });
  }

  remove(file) {
    this.files.delete(file);
    this.update();
  }

  update() {
    this.propagateChange(Array.from(this.files).map(uploadedFile => uploadedFile.id));
  }

  writeValue(files: { id: string, name: string, type: string, size: number }[]): void {
    files.forEach(file => {
      this.files.add(file);
    });
    this.update();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }
}

