import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../../../shared/models/user';
import {AppConfig} from '../../../app-config';
import {Observable} from 'rxjs';
import {ProfileRating} from '../models/help-rating';
import {UserProfileSkill} from '../models/user-skill';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) {
  }

  getContactByUsername(username: string): Observable<User> {
    return this.http.get<User>(`${AppConfig.API_ENDPOINT}/users/contacts/${username}`)
      .pipe(map(user => {
        user.profilePicture = `${AppConfig.API_ENDPOINT}/users/profile-picture/${user.profilePicture}`;
        return user;
      }));
  }

  getProfile(username: string): Observable<User> {
    return this.http.get<User>(`${AppConfig.API_ENDPOINT}/users/${username}`)
      .pipe(map(user => {
        user.profilePicture = `${AppConfig.API_ENDPOINT}/users/profile-picture/${user.profilePicture}`;
        return user;
      }));
  }

  getProfileSkills(username: string): Observable<UserProfileSkill[]> {
    return this.http.get<UserProfileSkill[]>(`${AppConfig.API_ENDPOINT}/users/${username}/skills`);
  }

  getProfileRating(username: string): Observable<ProfileRating> {
    return this.http.get<ProfileRating>(`${AppConfig.API_ENDPOINT}/users/${username}/rating`);
  }

  reportUser(username: string, reasonType: string, reasonText: string): Observable<void> {
    return this.http.post<void>(`${AppConfig.API_ENDPOINT}/users/${username}/report`, {
      type: reasonType,
      text: reasonText,
    });
  }
}
