import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../shared/models/user';
import {MatDialog} from '@angular/material/dialog';
import {ReportUserDialogComponent} from '../report-user-dialog/report-user-dialog.component';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.css']
})
export class ProfileHeaderComponent implements OnInit {

  @Input() user: User;

  constructor(
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
  }

  showReportDialog() {
    this.dialog.open(ReportUserDialogComponent, {
      width: '450px',
      data: {
        user: this.user,
      }
    });
  }

}
