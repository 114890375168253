import {Component, Input, OnInit} from '@angular/core';
import {UserPost} from '../../../../shared/models/user-post';

@Component({
  selector: 'app-dense-post-entry',
  templateUrl: './dense-post-entry.component.html',
  styleUrls: ['./dense-post-entry.component.css']
})
export class DensePostEntryComponent implements OnInit {
  @Input() post: UserPost;

  constructor() {
  }

  ngOnInit() {
  }

}
