import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../shared/models/user';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AdminClassService} from '../../shared/services/admin-class.service';
import {SchoolClassYear} from '../../shared/models/school-class-year';
import {SchoolClass} from '../../shared/models/school-class';
import {AdminUserService} from '../../shared/services/admin-user.service';
import {MessageService} from '../../../shared/services/message.service';

@Component({
  selector: 'app-admin-user-details-general',
  templateUrl: './admin-user-details-general.component.html',
  styleUrls: ['./admin-user-details-general.component.css']
})
export class AdminUserDetailsGeneralComponent implements OnInit {
  @Input() user: User;

  classFormGroup: FormGroup;
  classYears: SchoolClassYear[] = [];

  constructor(
    private fb: FormBuilder,
    private classService: AdminClassService,
    private messageService: MessageService,
    private userService: AdminUserService,
  ) {
    this.classFormGroup = this.fb.group({
      classYear: '',
      class: '',
    });

    this.classService.getClasses().subscribe(results => {
      this.classYears = results;
    });
  }

  ngOnInit() {
    if (this.user.schoolClass) {
      this.classFormGroup.patchValue({
        classYear: this.user.schoolClass.classYearId,
        class: this.user.schoolClass.id,
      });
    }
  }

  get classes(): SchoolClass[] {
    return this.classFormGroup.value.classYear && this.classYears.length > 0 ?
      this.classYears.find(classYear => classYear.id === this.classFormGroup.value.classYear).classes
      : [];
  }

  get classSubmittable(): boolean {
    if (!this.user.schoolClass) {
      return !!this.classFormGroup.value.class;
    }
    return this.user && this.classFormGroup.value.class !== this.user.schoolClass.id;
  }

  submitSchoolClass() {
    const value = this.classFormGroup.value;
    this.userService.update(this.user.id, {
      schoolClassYearId: value.classYear,
      schoolClassId: value.class,
    }).subscribe(() => {
      this.messageService.showMessage('Dem Nutzer wurde erfolgreich die Klasse zugeordnet.');
    }, () => {
      this.messageService.showMessage('Beim Speichern der Klasse ist ein Fehler aufgetreten.');
    });
  }

}
