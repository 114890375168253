import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SubjectTopic} from '../../shared/models/subject-topic';
import {SubjectsService} from '../../shared/services/subjects.service';
import {Subject} from '../../shared/models/subject';
import {ActivatedRoute, Router} from '@angular/router';
import {PostService} from '../shared/services/post.service';
import {MessageService} from '../../shared/services/message.service';

@Component({
  selector: 'app-post-form',
  templateUrl: './post-creation-form.component.html',
  styleUrls: ['./post-creation-form.component.css']
})
export class PostCreationFormComponent implements OnInit {
  labels = {
    lbl_title: 'Beschreibe kurz das Problem, bei dem du Hilfe benötigst',
    plc_title: 'z.B. Ich brauche noch dringend Hilfe vor der Klausur zur Kurvendiskussion',
    lbl_details: 'Beschreibe genauer, was du können musst und wobei du noch Schwierigkeiten hast',
    plc_details: 'z.B. Ich muss für die Klausur lernen, wie man eine Gedichtsanalyse schreibt. Aber ich habe noch nicht ganz verstanden, wie man Stilmittel analysiert und das Metrum herausfindet.',
  };
  form: FormGroup;
  loading = false;

  subjects: Subject[] = [];
  currentSubjectTopics: SubjectTopic[] = [];

  step = 0;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private subjectsService: SubjectsService,
    private postService: PostService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      type: new FormControl(null, [Validators.required]),
      subject: new FormControl(null, [Validators.required]),
      topic: new FormControl(0, [Validators.required]),
      title: new FormControl('', [Validators.required]),
      details: new FormControl('', [Validators.required]),
      attachments: [[]],
      dateDue: new FormControl(''),
    });

    const significantFields = [
      this.form.controls.type,
      this.form.controls.subject,
      this.form.controls.title,
      this.form.controls.details,
    ];
    significantFields.forEach((field, index) => {
      field.valueChanges.subscribe(() => {
        if (this.step === index) {
          this.step = index + 1;
        }
      });
    });

    this.subjectsService.getSubjects().subscribe(subjects => {
      this.subjects = subjects;

      const id = this.route.snapshot.paramMap.get('id');
      if (id) {
        this.postService.getPostById(id).subscribe(post => {
          this.form.patchValue({
            type: post.type.key,
            subject: post.subject.id,
            topic: post.topic ? post.topic.id : 'default',
            title: post.title,
            details: post.description,
            dateDue: post.dateDue ? new Date(post.dateDue) : '',
          });
          this.updateTopics(post.subject.id);
        });
        this.postService.getPostAttachmentFiles(id).subscribe(attachments => {
          this.form.patchValue({
            attachments: attachments.map(attachment => Object.assign(attachment, {}))
          });
        });
      }
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    const value = this.form.value;
    if (value.topic === 'default') {
      value.topic = null;
    }

    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.postService.updatePost(id, value).subscribe(() => {
        this.loading = false;
        this.messageService.showMessage('Die Änderungen wurden erfolgreich gespeichert!');
        this.router.navigate(['/posts/details', id]);
      }, error => {
        this.messageService.showMessage(error);
        this.loading = false;
      });
    } else {
      this.postService.addPost(value).subscribe(data => {
        this.router.navigate(['/posts/details', data.id]);
        this.messageService.showMessage('Dein Beitrag wurde erfolgreich erstellt!');
      }, error => {
        this.messageService.showMessage(error);
        this.loading = false;
      });
    }
  }

  updateType(event: string) {
    switch (event) {
      case 'REQUEST':
        this.labels = {
          lbl_title: 'Beschreibe kurz das Problem, bei dem du Hilfe benötigst',
          plc_title: 'z.B. Ich brauche noch dringend Hilfe vor der Klausur zur Kurvendiskussion',
          lbl_details: 'Beschreibe genauer, was du können musst und wobei du noch Schwierigkeiten hast',
          plc_details: 'z.B. Ich muss für die Klausur lernen, wie man eine Gedichtsanalyse schreibt. Aber ich habe noch nicht ganz verstanden, wie man Stilmittel analysiert und das Metrum herausfindet.',
        };
        break;
      case 'OFFER':
        this.labels = {
          lbl_title: 'Beschreibe kurz das Problem, bei dem du helfen willst',
          plc_title: 'z.B. Ich bin gut in Geometrie, braucht jemand Hilfe beim Satz des Pythagoras?',
          lbl_details: 'Beschreibe genauer, bei welchem Thema du helfen willst',
          plc_details: 'z.B. Ich bin auf der Suche nach Siebtklässlern, denen ich in Englisch weiterhelfen kann.',
        };
        break;
      case 'MATERIAL':
        this.labels = {
          lbl_title: 'Beschreibe kurz die Art und das Thema deines Lernmaterials',
          plc_title: 'z.B. Hier ist ein sehr guter Aufsatz über Napoleon Bonaparte zum allgemeinen Verständnis',
          lbl_details: 'Beschreibe genauer, inwieweit dein Lernmaterial anderen helfen kann',
          plc_details: 'z.B. Meine Präsentation über das Periodensystem der Elemente könnte dir weiterhelfen, wenn du kurz vor einem Chemie-Test stehst',
        };
        break;
    }
  }

  updateTopics(subjectId: string) {
    this.currentSubjectTopics = [];
    this.subjectsService.getSubjectTopics(subjectId)
      .subscribe(topics => {
        this.currentSubjectTopics = topics;
      });
  }
}
