import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminUserRoleService} from '../../shared/services/admin-user-role.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MessageService} from '../../../shared/services/message.service';

@Component({
  selector: 'app-admin-user-group-settings',
  templateUrl: './admin-user-role-settings.component.html',
  styleUrls: ['./admin-user-role-settings.component.css']
})
export class AdminUserRoleSettingsComponent implements OnInit {
  private form: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private userRoleService: AdminUserRoleService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: this.fb.control(''),
      permissions: null,
    });


    const id = this.route.snapshot.paramMap.get('groupId');
    if (id) {
      this.userRoleService.getDetails(id).subscribe(details => {
        this.form.patchValue({
          name: details.name,
          permissions: details.permissions,
        });
      });
    }
  }

  submit() {
    const data = this.form.value;
    data.permissions = data.permissions.filter(permission => permission.allowedFor !== 'None');

    const id = this.route.snapshot.paramMap.get('groupId');
    if (id) {
      this.userRoleService.update(id, data).subscribe(() => {
        this.messageService.showMessage('Die Änderungen wurden erfolgreich gespeichert.');
      });
    } else {
      this.userRoleService.create(data).subscribe(result => {
        this.router.navigate(['../' + result.id], {relativeTo: this.route});
        this.messageService.showMessage('Die Rolle wurde erfolgreich erstellt.');
      });
    }
  }
}
