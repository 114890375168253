import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserSettingsService} from '../../shared/services/user-settings.service';
import {MessageService} from '../../../shared/services/message.service';
import {UserSettings} from '../../../shared/models/user-settings';
import {validateUsername} from '../../../shared/validators/username.validator';
import {RegisterService} from '../../shared/services/register.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent implements OnInit {
  accountDetailsForm: FormGroup;
  wereAccountDetailsChanged = false;

  private initialSettings: UserSettings;
  private username: string;
  private usernameTimeout;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private registerService: RegisterService,
    private settingsService: UserSettingsService,
  ) {
  }

  ngOnInit() {
    this.accountDetailsForm = this.fb.group({
      username: ['', [Validators.required, validateUsername]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
    });

    this.settingsService.settings.subscribe(settings => {
      this.accountDetailsForm.patchValue(settings);
      this.initialSettings = settings;

      this.accountDetailsForm.valueChanges.subscribe(() => {
        this.wereAccountDetailsChanged = true;
      });

      this.accountDetailsForm.controls.username.valueChanges.subscribe(() => {
        clearTimeout(this.usernameTimeout);

        this.usernameTimeout = setTimeout(() => {
          this.checkUsername();
        }, 500);
      });
    });
  }

  checkUsername() {
    const username = this.accountDetailsForm.value.username;
    if (this.accountDetailsForm.controls.username.valid && username !== '' && username.toLowerCase() !== this.initialSettings.username.toLowerCase() && this.username !== username) {
      this.registerService.checkUsername(username).subscribe(isAvailable => {
        if (!isAvailable) {
          this.accountDetailsForm.controls.username.setErrors({notAvailable: true});
        }
      });

      this.username = username;
    }
  }

  saveAccountDetails() {
    const changed = {};
    Object.entries(this.accountDetailsForm.value).forEach(entry => {
      const [key, value] = entry;
      if (!this.initialSettings[key] || this.initialSettings[key] !== value) {
        changed[key] = value;
      }
    });
    this.settingsService.saveAccountDetails(changed).subscribe(success => {
      Object.assign(this.initialSettings, success);
      this.messageService.showMessage('Deine Einstellungen wurden erfolgreich gespeichert!');
      this.wereAccountDetailsChanged = false;
    }, error => {
      this.messageService.showMessage('Die Account-Einstellungen konnten leider nicht gespeichert werden.');
    });
  }
}
