import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {User} from '../../../shared/models/user';
import {HttpClient, HttpRequest, HttpResponse} from '@angular/common/http';
import {AppConfig} from '../../../app-config';
import {UserPending} from '../models/user-pending';
import {map} from 'rxjs/operators';
import {Permission} from '../models/permission';

@Injectable({
  providedIn: 'root'
})
export class AdminUserService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getUsers(sort = 'username', desc = false, page = 0, filter?: string): Observable<User[]> {
    const params = {sort, desc: desc.toString(), page: page.toString()};
    if (filter) {
      Object.assign(params, {filter});
    }
    return this.http.get<User[]>(`${AppConfig.API_ENDPOINT}/admin/users`, {params});
  }

  getCount(): Observable<number> {
    return this.http.get<{ count: number }>(`${AppConfig.API_ENDPOINT}/admin/users/count`)
      .pipe(map(count => count.count));
  }

  getPending(sort = 'dateCreated', desc = true, page = 0): Observable<UserPending[]> {
    return this.http.get<UserPending[]>(`${AppConfig.API_ENDPOINT}/admin/users/pending?sort=${sort}&desc=${desc}&page=${page}`);
  }

  getPendingCount(): Observable<number> {
    return this.http.get<{ count: number }>(`${AppConfig.API_ENDPOINT}/admin/users/pending/count`)
      .pipe(map(count => count.count));
  }

  getUserDetails(id: string): Observable<User> {
    return this.http.get<User>(`${AppConfig.API_ENDPOINT}/admin/users/${id}`)
      .pipe(map(user => {
        user.profilePicture = `${AppConfig.API_ENDPOINT}/users/profile-picture/${user.profilePicture}`;
        return user;
      }));
  }

  getUserPermissions(id: string): Observable<{
    individual: Permission[],
    role: Permission[],
  }> {
    return this.http.get<{
      individualPermissions: string[],
      rolePermissions: string[],
    }>(`${AppConfig.API_ENDPOINT}/admin/users/${id}/permissions`)
      .pipe(map(data => {
        return {
          individual: data.individualPermissions.map(perm => splitPermissionString(perm)),
          role: data.rolePermissions.map(perm => splitPermissionString(perm)),
        };
      }));
  }

  getUserPendingDetails(id: string): Observable<UserPending> {
    return this.http.get<UserPending>(`${AppConfig.API_ENDPOINT}/admin/users/pending/${id}`);
  }

  processBulkUsers(file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    const request = new HttpRequest('POST', `${AppConfig.API_ENDPOINT}/admin/users/bulk/process`, formData);
    const result = new Subject();

    this.http.request(request).subscribe(event => {
      if (event instanceof HttpResponse) {
        result.next(event.body);
        result.complete();
      }
    });

    return result.asObservable();
  }

  createBulkUsers(users): Observable<void> {
    return this.http.post<void>(`${AppConfig.API_ENDPOINT}/admin/users/bulk`, users);
  }

  createUser(user): Observable<UserPending> {
    return this.http.post<UserPending>(`${AppConfig.API_ENDPOINT}/admin/users`, user);
  }

  update(id: string, data): Observable<void> {
    return this.http.patch<void>(`${AppConfig.API_ENDPOINT}/admin/users/${id}`, data);
  }

  activateUser(userId: string): Observable<void> {
    return this.http.post<void>(`${AppConfig.API_ENDPOINT}/admin/users/${userId}/activate`, {});
  }

  banUser(userId: string, ban: boolean): Observable<void> {
    return this.http.post<void>(`${AppConfig.API_ENDPOINT}/admin/users/${userId}/ban`, {ban});
  }

  deleteUser(userId: string): Observable<void> {
    return this.http.delete<void>(`${AppConfig.API_ENDPOINT}/admin/users/${userId}`);
  }

  confirmUserPending(userId: string, reaction: boolean): Observable<void> {
    return this.http.post<void>(`${AppConfig.API_ENDPOINT}/admin/users/pending/${userId}/react`, {reaction});
  }

  deleteUserPending(userId: string): Observable<void> {
    return this.http.delete<void>(`${AppConfig.API_ENDPOINT}/admin/users/pending/${userId}`);
  }
}

export function splitPermissionString(perm: string): Permission {
  const parts = perm.split(':');
  return {
    permissionKey: parts[0],
    allowedFor: parts[1],
  };
}
