import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UserSettingsService} from '../../shared/services/user-settings.service';
import {MessageService} from '../../../shared/services/message.service';
import {UploadService} from '../../../upload/upload.service';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {AppConfig} from '../../../app-config';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.css']
})
export class ProfileSettingsComponent implements OnInit {
  profilePicture: string;
  isUploading = false;
  uploadProgress = 0;

  skillsForm: FormGroup;
  wereSkillsChanged = false;

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private settingsService: UserSettingsService,
    private uploadService: UploadService,
  ) {
  }

  ngOnInit() {
    this.profilePicture = this.authenticationService.currentUserValue.profilePicture;

    this.skillsForm = this.fb.group({
      skills: [[]],
    });
    this.skillsForm.controls.skills.valueChanges.subscribe(() => this.wereSkillsChanged = true);

    this.settingsService.skills.subscribe(skills => {
      this.skillsForm.patchValue({skills});

      this.skillsForm.valueChanges.subscribe(() => this.wereSkillsChanged = true);
    });
  }

  getProfilePicture(): string {
    return this.profilePicture ? `url(${this.profilePicture})` : '';
  }

  uploadProfilePicture(event) {
    const file: File = event.target.files[0];
    this.isUploading = true;

    const upload = this.uploadService.upload(file, 'users/profile-picture');
    upload.progress.subscribe(progress => {
      this.uploadProgress = progress;
    });

    upload.result.subscribe(result => {
      // @ts-ignore
      this.profilePicture = `${AppConfig.API_ENDPOINT}/users/profile-picture/${result.url}`;
      this.isUploading = false;
      this.uploadProgress = 0;

      this.authenticationService.updateUserData();
    });
  }

  saveSkills() {
    this.settingsService.saveSelectedSkills(this.skillsForm.value.skills).subscribe(() => {
      this.messageService.showMessage('Deine Fähigkeiten wurden erfolgreich gespeichert.');
    }, error => {
      console.log(error);
      this.messageService.showMessage('Deine ausgewählten Fähigkeiten konnten leider nicht gespeichert werden. Bitte versuche es später erneut.');
    });
  }
}
