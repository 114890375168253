import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {AdminReportService} from '../shared/services/admin-report.service';
import {merge} from 'rxjs';
import {map, startWith, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-admin-reports',
  templateUrl: './admin-reports.component.html',
  styleUrls: ['./admin-reports.component.css']
})
export class AdminReportsComponent implements OnInit, AfterViewInit {
  displayedColumns = ['user', 'post', 'reasonType', 'reasonText', 'dateCreated'];

  data = [];
  resultsLength = 0;
  isLoading = true;

    @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  constructor(
    private reportService: AdminReportService,
  ) {
  }

  ngOnInit() {
    this.reportService.getCount().subscribe(count => this.resultsLength = count);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          return this.reportService.getReports(this.sort.active, this.sort.direction === 'desc', this.paginator.pageIndex);
        }),
        map(data => {
          this.isLoading = false;

          return data;
        })
      ).subscribe(data => this.data = data);
  }

}
