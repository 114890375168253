import {Component, OnInit} from '@angular/core';
import {AdminUserService} from '../shared/services/admin-user.service';
import {MatDialog} from '@angular/material/dialog';
import {UsersAddListDialogComponent} from './users-add-list-dialog/users-add-list-dialog.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AdminUserRoleService} from '../shared/services/admin-user-role.service';
import {UserRole} from '../shared/models/user-role';
import {SchoolClassYear} from '../shared/models/school-class-year';
import {AdminClassService} from '../shared/services/admin-class.service';
import {MessageService} from '../../shared/services/message.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-admin-users-add',
  templateUrl: './admin-users-add.component.html',
  styleUrls: ['./admin-users-add.component.css']
})
export class AdminUsersAddComponent implements OnInit {
  singleUserForm: FormGroup;

  roles: UserRole[];
  classYears: SchoolClassYear[];

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private adminUsersService: AdminUserService,
    private adminUserRoleService: AdminUserRoleService,
    private adminClassesService: AdminClassService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
    this.singleUserForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      role: ['', Validators.required],
      schoolClassYear: '',
      schoolClass: '',
    });

    this.adminUserRoleService.getAll().subscribe(roles => {
      this.roles = roles;
      this.singleUserForm.patchValue({
        role: roles.find(role => role.isDefault).id,
      });
    });

    this.adminClassesService.getClasses().subscribe(classes => this.classYears = classes);
  }

  get classes() {
    return this.classYears.find(classYear => classYear.id === this.singleUserForm.value.schoolClassYear).classes;
  }

  processBulk(event) {
    const file: File = event.target.files[0];

    this.adminUsersService.processBulkUsers(file).subscribe(results => {
      this.openBulkConfirmationDialog(results);
    });
  }

  openBulkConfirmationDialog(bulkUsers) {
    const dialogRef = this.dialog.open(UsersAddListDialogComponent, {
      width: '800px',
      data: bulkUsers,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/admin/users/pending']);
      }
    });
  }

  onSingleUserSubmit() {
    const data = this.singleUserForm.value;

    this.adminUsersService.createUser(data).subscribe(user => {
      this.messageService.showMessage(`Der Nutzer ${user.username} wurde erfolgreich erstellt.`);
    }, () => {
      this.messageService.showMessage('Beim Erstellen des Nutzers ist leider ein Fehler aufgetreten.');
    });
  }

}
