import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {PostResponseService} from '../shared/services/post-response.service';
import {PostResponse} from '../shared/models/post-response';
import {MessageService} from '../../shared/services/message.service';
import {MatDialog} from '@angular/material/dialog';
import {PostResponseRefuseConfirmDialogComponent} from './post-response-refuse-confirm-dialog/post-response-refuse-confirm-dialog.component';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-post-responses',
  templateUrl: './post-responses.component.html',
  styleUrls: ['./post-responses.component.css']
})
export class PostResponsesComponent implements OnInit {
  responses: PostResponse[] = [];
  isLoading = false;
  isAtEnd = false;

  constructor(
    private dialog: MatDialog,
    private messageService: MessageService,
    private postResponseService: PostResponseService,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit() {
    this.loadResponses();
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    if (this.document.documentElement.scrollTop + 100 > this.document.documentElement.scrollHeight - this.document.documentElement.clientHeight) {
      this.loadResponses();
    }
  }

  loadResponses() {
    if (this.isLoading || this.isAtEnd) {
      return;
    }

    this.isLoading = true;
    this.postResponseService.getResponses(this.responses.length, 10)
      .subscribe(responses => {
        this.isLoading = false;
        if (responses.length < 10) {
          this.isAtEnd = true;
        }

        this.responses.push(...responses);
      }, () => {
        this.messageService.showMessage('Beim Laden der Antworten ist ein Fehler aufgetreten.');
      });
  }

  reactToResponse(response: PostResponse, reaction: boolean) {
    this.postResponseService.reactToResponse(response.id, response.post.id, reaction).subscribe(() => {
      if (reaction) {
        this.messageService.showMessage('Dieser Nutzer wurde dir als neuer Kontakt hinzugefügt!');
      } else {
        this.messageService.showMessage('Du hast diese Antwort erfolgreich abgelehnt.');
      }
      response.reaction = reaction;
    }, error => {
      switch (error.statusCode) {
        case 422:
          this.messageService.showMessage('Du hast bereits auf diese Antwort reagiert!');
          break;
        default:
          this.messageService.showMessage('Beim Reagieren auf diesen Nutzer ist leider ein Fehler aufgetreten. Bitte versuche es später erneut!');
          break;
      }
    });
  }

  showRefuseConfirmDialog(response: PostResponse) {
    const dialog = this.dialog.open(PostResponseRefuseConfirmDialogComponent, {data: {response}});
    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.reactToResponse(response, false);
      }
    });
  }
}
