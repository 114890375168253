import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminSubjectsComponent} from './admin-subjects/admin-subjects.component';
import {RouterModule} from '@angular/router';
import {AdminComponent} from './admin.component';
import {AdminUsersComponent} from './admin-users/admin-users.component';
import {AdminUserRolesComponent} from './admin-user-roles/admin-user-roles.component';
import {AdminUserRoleSettingsComponent} from './admin-user-roles/admin-user-role-settings/admin-user-role-settings.component';
import {AdminUsersAddComponent} from './admin-users-add/admin-users-add.component';
import {AdminPendingUsersComponent} from './admin-pending-users/admin-pending-users.component';
import {AdminPostsComponent} from './admin-posts/admin-posts.component';
import {AdminDashboardComponent} from './admin-dashboard/admin-dashboard.component';
import {AdminReportsComponent} from './admin-reports/admin-reports.component';
import {AdminSettingsComponent} from './admin-settings/admin-settings.component';
import {AdminClassesComponent} from './admin-classes/admin-classes.component';
import {AdminClassesCreateComponent} from './admin-classes/admin-classes-create/admin-classes-create.component';
import {AuthGuard} from '../shared/guards/auth.guard';
import {AdminUserDetailsComponent} from './admin-user-details/admin-user-details.component';
import {AdminSubjectSettingsComponent} from './admin-subjects/admin-subject-settings/admin-subject-settings.component';
import {AdminPostDetailsComponent} from './admin-post-details/admin-post-details.component';
import {AdminFeedbackComponent} from './admin-feedback/admin-feedback.component';

const routes = [
  {
    path: 'admin', component: AdminComponent, children: [
      {path: '', component: AdminDashboardComponent},
      {
        path: 'users',
        component: AdminUsersComponent,
        canActivate: [AuthGuard],
        data: {permissions: ['ViewUser:All']},
      },
      {
        path: 'users/roles',
        component: AdminUserRolesComponent,
        canActivate: [AuthGuard],
        data: {permissions: ['ViewUserRole:All']},
      },
      {
        path: 'users/roles/add',
        component: AdminUserRoleSettingsComponent,
        canActivate: [AuthGuard],
        data: {permissions: ['CreateUserRole:All']},
      },
      {
        path: 'users/roles/:groupId',
        component: AdminUserRoleSettingsComponent,
        canActivate: [AuthGuard],
        data: {permissions: ['ViewUserRole:Every']},
      },
      {
        path: 'users/add',
        component: AdminUsersAddComponent,
        canActivate: [AuthGuard],
        data: {permissions: ['CreateUserPending:All']},
      },
      {
        path: 'users/pending',
        component: AdminPendingUsersComponent,
        canActivate: [AuthGuard],
        data: {permissions: ['ViewUserPending:All']},
      },
      {
        path: 'users/:id',
        component: AdminUserDetailsComponent,
        canActivate: [AuthGuard],
        data: {permissions: ['ViewUser:All']},
      },
      {
        path: 'posts',
        component: AdminPostsComponent,
        canActivate: [AuthGuard],
        data: {permissions: ['ViewPost:All']},
      },
      {
        path: 'posts/:id',
        component: AdminPostDetailsComponent,
        canActivate: [AuthGuard],
        data: {permissions: ['ViewPost:All']},
      },
      {
        path: 'subjects',
        component: AdminSubjectsComponent,
        canActivate: [AuthGuard],
        data: {permissions: ['ViewSubject:All']},
      },
      {
        path: 'subjects/create',
        component: AdminSubjectSettingsComponent,
        canActivate: [AuthGuard],
        data: {permissions: ['CreateSubject:All']},
      },
      {
        path: 'subjects/:subjectId',
        component: AdminSubjectSettingsComponent,
        canActivate: [AuthGuard],
        data: {permissions: ['WriteSubject:All']},
      },
      {
        path: 'reports',
        component: AdminReportsComponent,
        canActivate: [AuthGuard],
        data: {permissions: ['ViewReport:All']},
      },
      {
        path: 'feedback',
        component: AdminFeedbackComponent,
        canActivate: [AuthGuard],
        data: {permissions: ['ViewFeedback:All']},
      },
      {
        path: 'classes',
        component: AdminClassesComponent,
        canActivate: [AuthGuard],
        data: {permissions: ['ViewSchoolClass:All']},
      },
      {
        path: 'classes/add',
        component: AdminClassesCreateComponent,
        canActivate: [AuthGuard],
        data: {permissions: ['CreateSchoolClass:All']},
      },
      {
        path: 'classes/:classId',
        component: AdminClassesCreateComponent,
        canActivate: [AuthGuard],
        data: {permissions: ['WriteSchoolClass:All']},
      },
      {
        path: 'settings',
        component: AdminSettingsComponent,
        canActivate: [AuthGuard],
        data: {permissions: ['ViewSettings:All']},
      },
    ],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule,
  ]
})
export class AdminRoutingModule {
}
