import {Component, OnInit} from '@angular/core';
import {AdminPostService} from '../shared/services/admin-post.service';
import {UserPost} from '../../shared/models/user-post';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminUserBanDialogComponent} from '../admin-user-details/admin-user-ban-dialog/admin-user-ban-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {User} from '../../shared/models/user';
import {AdminPostDeleteDialogComponent} from './admin-post-delete-dialog/admin-post-delete-dialog.component';

@Component({
  selector: 'app-admin-post-details',
  templateUrl: './admin-post-details.component.html',
  styleUrls: ['./admin-post-details.component.css']
})
export class AdminPostDetailsComponent implements OnInit {
  post: UserPost;
  user: User;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private postService: AdminPostService,
  ) {
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.postService.getPostById(id).subscribe(post => {
      this.post = post;
      this.user = post.user;
    });
  }

  showDeleteDialog() {
    const ref = this.dialog.open(AdminPostDeleteDialogComponent, {
      width: '450px',
      data: {
        post: this.post,
      },
    });
    ref.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/admin/posts']);
      }
    });
  }

  showUserBanDialog() {
    const ref = this.dialog.open(AdminUserBanDialogComponent, {
      width: '450px',
      data: {
        user: this.user,
      }
    });
  }

}
