import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PostType, UserPost} from '../../../shared/models/user-post';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../app-config';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminPostService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getPosts(sort = 'title', desc = false, page = 0, filter?: string): Observable<UserPost[]> {
    const params = {sort, desc: desc.toString(), page: page.toString()};
    if (filter) {
      Object.assign(params, {filter});
    }
    return this.http.get<UserPost[]>(`${AppConfig.API_ENDPOINT}/admin/posts`, {params});
  }

  getCount(): Observable<number> {
    return this.http.get<{ count: number }>(`${AppConfig.API_ENDPOINT}/admin/posts/count`)
      .pipe(map(count => count.count));
  }

  getPostsByUser(userId: string): Observable<UserPost[]> {
    return this.http.get<UserPost[]>(`${AppConfig.API_ENDPOINT}/admin/posts/user/${userId}`)
      .pipe(map(posts => {
        return posts.map(post => {
          post.type = PostType.getByKey(post.type);
          return post;
        });
      }));
  }

  getPostById(postId: string): Observable<UserPost> {
    return this.http.get<UserPost>(`${AppConfig.API_ENDPOINT}/admin/posts/${postId}`)
      .pipe(map(post => {
        post.type = PostType.getByKey(post.type);
        post.user.profilePicture = `${AppConfig.API_ENDPOINT}/users/profile-picture/${post.user.profilePicture}`;
        return post;
      }));
  }

  deleteById(postId: string): Observable<void> {
    return this.http.delete<void>(`${AppConfig.API_ENDPOINT}/admin/posts/${postId}`);
  }

  deleteByUser(userId: string): Observable<void> {
    return this.http.delete<void>(`${AppConfig.API_ENDPOINT}/admin/posts/user/${userId}`);
  }
}
