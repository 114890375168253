import {SchoolClass} from '../../admin/shared/models/school-class';
import {UserRole} from '../../admin/shared/models/user-role';

export class User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  profilePicture: string;
  role: UserRole;
  permissions: string[];
  hasCompletedWalkthrough: boolean;
  schoolClass?: SchoolClass;
  dateCreated: string;
  status: string;
  token: string;
}
