import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {UserSettings} from '../../../shared/models/user-settings';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../../app-config';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  private settingsSubject = new Subject<UserSettings>();
  private skillsSubject = new BehaviorSubject<{ topicId: string, ability: number }[]>([]);
  settings = this.settingsSubject.asObservable();
  skills = this.skillsSubject.asObservable();

  constructor(
    private http: HttpClient,
  ) {
  }

  loadSettings() {
    this.http.get<UserSettings>(`${AppConfig.API_ENDPOINT}/users/settings`)
      .subscribe(settings => {
        this.settingsSubject.next(settings);
      });
  }

  loadSkills() {
    this.http.get<{ topicId: string, ability: number }[]>(`${AppConfig.API_ENDPOINT}/users/skills`)
      .subscribe(skills => {
        this.skillsSubject.next(skills);
      });
  }

  saveAccountDetails(updatedData: {
    username?: string,
    firstName?: string,
    lastName?: string,
  }): Observable<any> {
    if (Object.keys(updatedData).length === 0) {
      return of(undefined);
    }
    return this.http.patch(`${AppConfig.API_ENDPOINT}/users/settings/account-details`, updatedData);
  }

  saveSelectedSkills(skills: { topicId: string, ability: number }[]): Observable<any> {
    return this.http.put(`${AppConfig.API_ENDPOINT}/users/skills`, skills);
  }

  savePassword(updatedData: {
    currentPassword: string,
    password: string,
  }): Observable<void> {
    return this.http.patch<void>(`${AppConfig.API_ENDPOINT}/users/settings/password`, updatedData);
  }

  saveEmail(updatedEmail: string): Observable<void> {
    return this.http.patch<void>(`${AppConfig.API_ENDPOINT}/users/settings/email`, {email: updatedEmail});
  }
}
