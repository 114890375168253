import {Component, Input, OnInit} from '@angular/core';
import {UserPostAttachment} from '../../shared/models/user-post-attachment';
import {AppConfig} from '../../../app-config';
import {getFileIcon} from '../../../shared/pipes/file-icons.pipe';
import {UserPost} from '../../../shared/models/user-post';

@Component({
  selector: 'app-post-attachments',
  templateUrl: './post-attachments.component.html',
  styleUrls: ['./post-attachments.component.css']
})
export class PostAttachmentsComponent implements OnInit {

  @Input() post: UserPost;
  @Input() attachments: UserPostAttachment[];

  constructor() {
  }

  ngOnInit() {
  }

  openAttachment(attachment: UserPostAttachment) {
    window.open(this.getAttachmentUrl(attachment), '_BLANK');
  }

  getAttachmentUrl(attachment: UserPostAttachment): string {
    return `${AppConfig.API_ENDPOINT}/post/${this.post.id}/attachment/${attachment.id}`;
  }

  getAttachmentImage(attachment: UserPostAttachment): string {
    if (attachment.type.startsWith('image')) {
      return this.getAttachmentUrl(attachment);
    }
    return getFileIcon(attachment.type);
  }

}
