import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AdminUserService} from '../../shared/services/admin-user.service';
import {MessageService} from '../../../shared/services/message.service';

@Component({
  selector: 'app-users-add-list-dialog',
  templateUrl: './users-add-list-dialog.component.html',
  styleUrls: ['./users-add-list-dialog.component.css']
})
export class UsersAddListDialogComponent implements OnInit {
  displayedColumns = ['prename', 'surname', 'email', 'schoolClass', 'role', 'invitationCode'];
  isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<UsersAddListDialogComponent>,
    private userService: AdminUserService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
  }

  submit() {
    this.isLoading = true;
    this.userService.createBulkUsers(this.data.users).subscribe(() => {
      this.messageService.showMessage('Die Nutzer wurden erfolgreich hinzugefügt.');
      this.dialogRef.close(true);
    }, error => {
      this.isLoading = false;
      this.messageService.showMessage('Beim Hinzufügen der Nutzer ist ein Fehler aufgetreten.');
    });
  }

}
