import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {Router} from '@angular/router';
import {MessageService} from '../../shared/services/message.service';
import {validateUsername} from '../../shared/validators/username.validator';
import {passwordMatchValidator} from '../../shared/validators/password-match.validator';
import {validatePassword} from '../../shared/validators/password.validator';
import {ErrorStateMatcher} from '@angular/material/core';
import {RegisterService} from '../shared/services/register.service';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && (control.invalid || (form.errors && form.errors.passwordsDoNotMatch));
  }
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  private invitationCode: string;
  private username: string;
  private email: string;
  private invitationTimeout;
  private usernameTimeout;
  private emailTimeout;

  invitationData: FormGroup;
  generalData: FormGroup;
  skillsData: FormGroup;

  invitationCodeState = 0;
  passwordHide = true;

  errorMatcher = new CrossFieldErrorMatcher();

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private registerService: RegisterService,
    private router: Router
  ) {
    // Leitet den Nutzer zur Startseite weiter, wenn er bereits eingeloggt ist
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.invitationData = this.fb.group({
      invitationCode: [''],
    });

    this.generalData = this.fb.group({
      username: ['', [Validators.required, validateUsername]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, validatePassword]],
      confirmPassword: ['', [Validators.required]],
    }, {validators: passwordMatchValidator});

    this.skillsData = this.fb.group({
      skills: [[]],
    });

    this.invitationData.valueChanges.subscribe(() => {
      this.invitationCodeState = 0;
      clearTimeout(this.invitationTimeout);

      this.invitationTimeout = setTimeout(() => {
        this.checkInvitationCode();
      }, 500);
    });

    this.generalData.controls.username.valueChanges.subscribe(() => {
      clearTimeout(this.usernameTimeout);

      this.usernameTimeout = setTimeout(() => {
        this.checkUsername();
      }, 500);
    });

    this.generalData.controls.email.valueChanges.subscribe(() => {
      clearTimeout(this.emailTimeout);

      this.emailTimeout = setTimeout(() => {
        this.checkEmail();
      }, 500);
    });
  }

  checkInvitationCode() {
    const invitationCode = this.invitationData.value.invitationCode;
    if (this.invitationData.controls.invitationCode.valid && invitationCode !== '' && invitationCode !== this.invitationCode) {
      this.invitationCodeState = 3;

      // Sendet den Einladungscode zur Überprüfung an den Server und lädt gegebenenfalls bereits vorhandene Daten
      this.registerService.checkInvitationCode(invitationCode).subscribe(data => {
        this.invitationCodeState = 1;
        this.generalData.patchValue(data);
      }, error => {
        this.invitationCodeState = 2;
        switch (error.statusCode) {
          case 404:
            this.messageService.showMessage('Der Einladungscode ist ungültig');
            this.invitationData.controls.invitationCode.setErrors({
              invalid: true,
            });
            break;
        }
      });

      this.invitationCode = invitationCode;
    }
  }

  // Überprüft ob der Nutzername verfügbar ist
  checkUsername() {
    const username = this.generalData.value.username;
    if (this.generalData.controls.username.valid && username !== '' && username !== this.username) {
      this.registerService.checkUsername(username, this.invitationCode).subscribe(isAvailable => {
        if (!isAvailable) {
          this.generalData.controls.username.setErrors({notAvailable: true});
        }
      });

      this.username = username;
    }
  }

  // Überprüft ob die eingegebene E-Mail-Adresse verfügbar ist
  checkEmail() {
    const email = this.generalData.value.email;
    if (this.generalData.controls.email.valid && email !== '' && email !== this.email) {
      this.registerService.checkEmail(email, this.invitationCode).subscribe(isAvailable => {
        if (!isAvailable) {
          this.generalData.controls.email.setErrors({notAvailable: true});
        }
      });

      this.email = email;
    }
  }

  onStepperChange() {
    this.checkInvitationCode();
  }

  onSubmit() {
    const data = Object.assign(this.generalData.value, this.skillsData.value);

    if (this.invitationCodeState === 1) {
      data.invitationCode = this.invitationCode;
    }

    this.registerService.register(data)
      .pipe(first())
      .subscribe(result => {
          if (result) {
            this.messageService.showMessage('Wir haben dir eine Bestätigungsmail gesendet. Einfach nur deine E-Mail bestätigen und schon kannst du dich einloggen!');
          } else {
            this.messageService.showMessage('Wir haben dir eine Bestätigungsmail gesendet. Nachdem du deine E-Mail bestätigt hast, werden wir deine Registrierungsanfrage überprüfen!');
          }
          this.router.navigate(['/']);
        },
        error => {
          this.messageService.showMessage('Leider ist beim Erstellen deines Accounts dieser Fehler aufgetreten. Bitte versuche es später erneut.');
        }
      );
  }
}

