import {SubjectTopic} from './subject-topic';
import {Subject} from './subject';
import {User} from './user';

export class UserPost {
  id: string;
  type: PostType;
  title: string;
  description: string;
  subject: Subject;
  topic?: SubjectTopic;
  classYear: { name: string };
  dateCreated: string;
  dateDue: string;
  responseCount: number;
  isByUser: boolean;
  hasUserRespondedAlready: boolean;
  isArchived: boolean;
  user?: User;
}

export class PostType {
  static readonly REQUEST = new PostType('REQUEST', 'Anfrage', 'Suche');
  static readonly OFFER = new PostType('OFFER', 'Angebot', 'Biete');
  static readonly MATERIAL = new PostType('MATERIAL', 'Lernmaterial', 'Material');

  private constructor(public readonly key: string, public readonly long: string, public readonly short: string) {
  }

  static getByKey(key) {
    const lower = key.toLowerCase();
    if (lower === 'request') {
      return this.REQUEST;
    } else if (lower === 'offer') {
      return this.OFFER;
    } else {
      return this.MATERIAL;
    }
  }


  toString() {
    return this.key;
  }
}
