import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../../app-config';

@Injectable({
  providedIn: 'root'
})
export class WalkthroughService {
  readonly walkthrough = [
    {
      title: 'Einführung',
      content: 'Willkommen auf der Lernplattform! Damit du die Plattform in vollem Umfang nutzen kannst, würden wir dir gerne die Anfänge erklären!',
    },
    {
      title: 'Feed',
      content: 'Dies ist dein persönlicher Feed. Hier werden Beiträge passend deiner ausgewählten Fähigkeiten angezeigt. Du kannst die Beiträge anklicken, dir mehr Informationen ansehen und mit ihnen interagieren.',
      selector: 'feed',
      direction: 'right',
    },
    {
      title: 'Beiträge erstellen',
      content: 'Hier kannst du selbst Hilfeanfragen und -angebote sowie Lernmaterialien erstellen. Sobald jemand auf deinen Beitrag antwortet, erhältst du eine Benachrichtigung und kannst entscheiden, ob du einen Chat starten möchtest.',
      selector: 'createPost',
      direction: 'top',
    },
    {
      title: 'Antworten',
      content: 'Sobald du eine neue Antwort auf einen deiner Beiträge erhältst, wird diese hier angezeigt.',
      selector: 'responses',
      direction: 'left',
    },
    {
      title: 'Nach Hause telefonieren',
      content: 'Egal wo du bist: Mit diesem Button kannst du jederzeit zum Feed zurückgelangen.',
      selector: 'home',
      direction: 'bottom',
      background: '#ffffff',
    },
    {
      title: 'Suchen und entdecken',
      content: 'Über die Lupe kannst du alle Posts nach deinen Belieben durchsuchen und beispielsweise nach Fach oder Typ filtern.',
      selector: 'search',
      direction: 'bottom',
      background: '#ffffff',
    },
    {
      title: 'Benachrichtigungen',
      content: 'Alle wichtigen Mitteilungen, beispielsweise neue Antworten auf deine Beiträge, werden an dieser Stelle übersichtlich angezeigt.',
      selector: 'notifications',
      direction: 'bottom',
      background: '#ffffff',
    },
    {
      title: 'Nachrichten',
      content: 'Hier gelangst du zu deinen Chats mit deinen zukünftigen Kontakten. Kontakte erhältst du durch das Antworten auf Beiträge.',
      selector: 'messages',
      direction: 'bottom',
      background: '#ffffff',
    },
    {
      title: 'Nutzer',
      content: 'Dein Profilbild ist der Zugang zu jeglichen Informationen und Einstellungen, die nur dich betreffen. Unter anderem kannst du hier deine Kontoeinstellungen ändern, dich abmelden und deine Beiträge einsehen.',
      selector: 'user',
      direction: 'bottom',
    }
  ];
  private currentStepIndex = 0;
  private hasCompleted = false;

  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient,
  ) {
    this.authenticationService.currentUser.subscribe(user => {
      if (user) {
        this.hasCompleted = user.hasCompletedWalkthrough;
      }
    });
  }

  get currentStep() {
    return this.walkthrough[this.currentStepIndex];
  }

  isFirst() {
    return this.currentStepIndex === 0;
  }

  hasNextStep() {
    return this.currentStepIndex < this.walkthrough.length - 1;
  }

  isDisplayed() {
    return this.authenticationService.currentUserValue && !this.hasCompleted;
  }

  previous() {
    if (this.currentStepIndex > 0) {
      this.currentStepIndex--;
    }
  }

  next() {
    this.currentStepIndex++;
    if (this.currentStepIndex >= this.walkthrough.length) {
      this.complete();
    }
  }

  complete() {
    this.hasCompleted = true;
    this.http.post(`${AppConfig.API_ENDPOINT}/users/walkthrough-completed`, {completed: true}).subscribe();
  }
}
