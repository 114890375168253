import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {AdminUserService} from '../shared/services/admin-user.service';
import {UserPending} from '../shared/models/user-pending';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, Subject} from 'rxjs';
import {map, startWith, switchMap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {AdminPendingUserConfirmationDialogComponent} from './admin-pending-user-confirmation-dialog/admin-pending-user-confirmation-dialog.component';
import {AdminPendingUserDeleteDialogComponent} from './admin-pending-user-delete-dialog/admin-pending-user-delete-dialog.component';

@Component({
  selector: 'app-admin-pending-users',
  templateUrl: './admin-pending-users.component.html',
  styleUrls: ['./admin-pending-users.component.css']
})
export class AdminPendingUsersComponent implements OnInit, AfterViewInit {
  displayedColumns = ['lastName', 'invitationCode', 'role', 'class', 'dateCreated', 'status', 'buttons'];

  data: UserPending[] = [];
  resultsLength = 0;
  isLoading = true;

  private update = new Subject();

  // @ts-ignore
  @ViewChild(MatSort, {static: false}) private sort: MatSort;
  // @ts-ignore
  @ViewChild(MatPaginator, {static: false}) private paginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    private userService: AdminUserService,
  ) {
  }

  ngOnInit() {
    this.userService.getPendingCount().subscribe(count => this.resultsLength = count);
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page, this.update)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          return this.userService.getPending(this.sort.active, this.sort.direction === 'desc', this.paginator.pageIndex);
        }),
        map(data => {
          this.isLoading = false;

          return data;
        })
      ).subscribe(data => this.data = data);
  }

  getStatusText(status: string) {
    switch (status) {
      case 'NEEDS_CONFIRMATION':
        return 'Benötigt manuelle Bestätigung';
      case 'NEEDS_VERIFICATION':
        return 'E-Mail-Verifizierung ausstehend';
      case 'NEEDS_REGISTRATION':
        return 'Registrierung durch Nutzer erforderlich';
      default:
        return '';
    }
  }

  showConfirmationDialog(user: UserPending) {
    if (user.status === 'NEEDS_CONFIRMATION') {
      const ref = this.dialog.open(AdminPendingUserConfirmationDialogComponent, {
        width: '450px',
        data: {
          userId: user.id,
        }
      });
      ref.afterClosed().subscribe(result => {
        if (result) {
          this.update.next(null);
        }
      });
    }
  }

  showDeleteDialog(user: UserPending) {
    const ref = this.dialog.open(AdminPendingUserDeleteDialogComponent, {
      width: '450px',
      data: {
        userId: user.id,
      }
    });
    ref.afterClosed().subscribe(result => {
      if (result) {
        this.update.next(null);
      }
    });
  }
}
