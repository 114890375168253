import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../../app-config';
import {ChatConversation} from '../../../shared/services/contact.service';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {PostType} from '../../../../shared/models/user-post';

@Injectable({
  providedIn: 'root'
})
export class ChatConversationService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getConversations(username: string): Observable<ChatConversation[]> {
    return this.http.get<ChatConversation[]>(`${AppConfig.API_ENDPOINT}/chats/${username}/conversations`)
      .pipe(map(conversations => conversations.map(conversation => {
        conversation.post.type = PostType.getByKey(conversation.post.type);
        return conversation;
      })));
  }

  reactToConversation(conversation: ChatConversation, reaction: boolean, rating: number) {
    return this.http.patch<void>(`${AppConfig.API_ENDPOINT}/chats/conversations/${conversation.id}`, {
      reaction,
      rating,
    });
  }

}
