import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, Subject} from 'rxjs';

@Component({
  selector: 'app-filter-checkboxes',
  templateUrl: './filter-checkboxes.component.html',
  styleUrls: ['./filter-checkboxes.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterCheckboxesComponent),
      multi: true,
    }
  ]
})
export class FilterCheckboxesComponent implements OnInit, ControlValueAccessor {
  filterGroup: FormGroup;
  itemsValue;
  newValues = new Subject<[]>();

  private propagateChange = (_: any) => {
  };

  constructor(
    private fb: FormBuilder,
  ) {
    this.filterGroup = this.fb.group({
      filter: this.fb.array([]),
    });

  }

  ngOnInit() {
    this.filterGroup.valueChanges.subscribe(change => {
      if (this.itemsValue) {
        const selectedItems = this.itemsValue.filter((item, index) => change.filter[index]).map(item => item.id);
        this.propagateChange(selectedItems);
      }
    });
  }

  get checkboxes(): FormArray {
    return this.filterGroup.controls.filter as FormArray;
  }

  @Input()
  set items(observable: Observable<string[]>) {
    observable.subscribe(items => {
      for (let i = this.checkboxes.length - 1; i > items.length; i--) {
        this.checkboxes.removeAt(i);
      }

      items.forEach(() => this.checkboxes.push(this.fb.control(false)));
      this.itemsValue = items;

      this.newValues.subscribe((newValue: string[]) => {
        newValue.forEach(id => {
          const index = this.itemsValue.findIndex(item => item.id === id);
          this.checkboxes.at(index).patchValue(true);
        });
      });
    });
  }

  writeValue(obj: any): void {
    this.newValues.next(obj);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }
}
