import {Pipe, PipeTransform} from '@angular/core';

const mimeTypes = {
  'audio/aac': 'audio',
  'application/x-abiword': '.abw',
  'application/x-freearc': '.arc',
  'video/x-msvideo': 'video',
  'application/vnd.amazon.ebook': '.azw',
  'application/octet-stream': '.bin',
  'image/bmp': 'image',
  'application/x-bzip': '.bz',
  'application/x-bzip2': '.bz2',
  'application/x-csh': '.csh',
  'text/css': '.css',
  'text/csv': '.csv',
  'application/msword': 'word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'word',
  'application/vnd.ms-fontobject': '.eot',
  'application/epub+zip': '.epub',
  'application/gzip': 'archive',
  'image/gif': 'image',
  'text/html': '.html',
  'image/vnd.microsoft.icon': '.ico',
  'text/calendar': '.ics',
  'application/java-archive': 'java',
  'image/jpeg': 'image',
  'text/javascript': '.js',
  'application/json': '.json',
  'application/ld+json': '.jsonld',
  'audio/midi': '.midi',
  'audio/mpeg': 'audio',
  'video/mpeg': 'video',
  'application/vnd.apple.installer+xml': '.mpkg',
  'application/vnd.oasis.opendocument.presentation': '.odp',
  'application/vnd.oasis.opendocument.spreadsheet': '.ods',
  'application/vnd.oasis.opendocument.text': 'word',
  'application/word': 'word',
  'audio/ogg': 'audio',
  'video/ogg': 'video',
  'application/ogg': '.ogx',
  'audio/opus': 'audio',
  'font/otf': '.otf',
  'image/png': 'image',
  'application/pdf': 'pdf',
  'application/php': '.php',
  'application/vnd.ms-powerpoint': 'powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'powerpoint',
  'application/mspowerpoint': 'powerpoint',
  'application/x-rar-compressed': 'archive',
  'application/rtf': 'text',
  'application/x-sh': '.sh',
  'image/svg+xml': 'svg',
  'application/x-shockwave-flash': '.swf',
  'application/x-tar': 'archive',
  'image/tiff': '.tiff',
  'video/mp2t': '.ts',
  'font/ttf': '.ttf',
  'text/plain': 'text',
  'application/vnd.visio': '.vsd',
  'audio/wav': 'audio',
  'audio/webm': 'audio',
  'video/webm': 'video',
  'image/webp': 'image',
  'font/woff': '.woff',
  'font/woff2': '.woff2',
  'application/xhtml+xml': '.xhtml',
  'application/vnd.ms-excel': 'excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel',
  'application/msexcel': 'excel',
  'application/xml': '.xml',
  'application/vnd.mozilla.xul+xml': '.xul',
  'application/zip': 'archive',
  'video/3gpp': 'video',
  'video/3gpp2': 'video',
  'application/x-7z-compressed': 'archive',
  'application/x-latex': 'tex',
  'application/x-tex': 'tex',
  'video/mp4': 'video',
};

@Pipe({
  name: 'fileIcons'
})
export class FileIconsPipe implements PipeTransform {

  transform(value: string[], args?: any): any {
    return Array.from(new Set(value.map(mime => {
      if (!mimeTypes[mime]) {
        return mime;
      }
      return mimeTypes[mime];
    }))).map(type => {
      if (type.includes('/') || type.includes('.')) {
        return type;
      }
      return `<img src="/assets/file-icons/${type}.svg">`;
    });
  }
}

export function getFileIcon(mimeType: string) {
  return `/assets/file-icons/${mimeTypes[mimeType]}.svg`;
}
