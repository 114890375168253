import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormComponent} from './form/form.component';
import {MaterialModule} from '../shared/material/material.module';
import {FlexModule} from '@angular/flex-layout';
import {PipeModule} from '../shared/modules/pipe.module';

@NgModule({
  declarations: [
    FormComponent,
  ],
  exports: [
    FormComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexModule,
    PipeModule,
  ]
})
export class UploadModule {
}
