import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserPost} from '../../../shared/models/user-post';
import {PostService} from '../../shared/services/post.service';
import {MessageService} from '../../../shared/services/message.service';

@Component({
  selector: 'app-close-dialog',
  templateUrl: './close-dialog.component.html',
  styleUrls: ['./close-dialog.component.css']
})
export class CloseDialogComponent implements OnInit {
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<CloseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      post: UserPost,
    },
    private postService: PostService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
  }

  closePost() {
    this.isLoading = true;
    this.postService.closePost(this.data.post.id).subscribe(() => {
      this.messageService.showMessage('Der Beitrag wurde erfolgreich abgeschlossen.');
      this.dialogRef.close(true);
    }, () => {
      this.isLoading = false;
      this.messageService.showMessage('Beim Archivieren dieses Beitrags ist ein Fehler aufgetreten.');
    });
  }

}
