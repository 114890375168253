import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {RegisterComponent} from './register/register.component';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from '../shared/guards/auth.guard';
import {PostDetailsComponent} from './post-details/post-details.component';
import {MessagesComponent} from './messages/messages.component';
import {SearchComponent} from './search/search.component';
import {HomeComponent} from './home/home.component';
import {UserComponent} from './user/user.component';
import {PostsOwnComponent} from './posts-own/posts-own.component';
import {SettingsComponent} from './settings/settings.component';
import {MainComponent} from './main.component';
import {PostCreationFormComponent} from './post-creation-form/post-creation-form.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ResetPasswordChangeComponent} from './reset-password/reset-password-change/reset-password-change.component';
import {VerifyEmailComponent} from './register/verify-email/verify-email.component';
import {PostResponsesComponent} from './post-responses/post-responses.component';
import {ChatConversationsComponent} from './messages/chat-conversations/chat-conversations.component';

const routes = [
  {
    path: '', component: MainComponent, children: [
      {path: '', component: HomeComponent},
      {path: 'posts', component: SearchComponent, canActivate: [AuthGuard]},
      {path: 'posts/own', component: PostsOwnComponent, canActivate: [AuthGuard]},
      {path: 'posts/details/:id', component: PostDetailsComponent, canActivate: [AuthGuard]},
      {path: 'posts/edit/:id', component: PostCreationFormComponent, canActivate: [AuthGuard]},
      {path: 'posts/create', component: PostCreationFormComponent, canActivate: [AuthGuard]},
      {path: 'responses', component: PostResponsesComponent, canActivate: [AuthGuard]},
      {path: 'user/:username', component: UserComponent, canActivate: [AuthGuard]},
      {
        path: 'settings', canActivate: [AuthGuard],
        children: [
          {path: '', redirectTo: 'account', pathMatch: 'full'},
          {path: ':tab', component: SettingsComponent, canActivate: [AuthGuard]},
        ]
      },
      {path: 'register', component: RegisterComponent},
      {path: 'login', component: LoginComponent},
      {path: 'verify', component: VerifyEmailComponent},
      {path: 'password-reset', component: ResetPasswordComponent},
      {path: 'password-reset/reset', component: ResetPasswordChangeComponent},
      {path: 'messages', component: MessagesComponent, canActivate: [AuthGuard]},
      {
        path: 'messages/:username', canActivate: [AuthGuard], children: [
          {path: '', component: MessagesComponent, canActivate: [AuthGuard], pathMatch: 'full'},
          {path: 'posts', component: ChatConversationsComponent, canActivate: [AuthGuard]}
        ]
      },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule,
  ]
})
export class MainRoutingModule {
}
