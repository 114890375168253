import {Component, OnInit} from '@angular/core';
import {UserSettingsService} from '../shared/services/user-settings.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  private tabs = ['account', 'profile', 'security', 'email'];
  selectedTab = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: UserSettingsService,
  ) {
  }

  ngOnInit() {
    this.settingsService.loadSettings();
    this.settingsService.loadSkills();

    if (this.route.snapshot.paramMap.has('tab')) {
      const tab = this.route.snapshot.paramMap.get('tab');
      if (this.tabs.includes(tab)) {
        this.selectedTab = this.tabs.indexOf(tab);
      }
    }
  }

  onSwitchTab(event) {
    this.router.navigate(['/settings', this.tabs[event.index]]);
  }

}
