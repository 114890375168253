import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../../app-config';
import {SchoolClassYear} from '../models/school-class-year';

@Injectable({
  providedIn: 'root'
})
export class AdminClassService {
  private classes = new BehaviorSubject<SchoolClassYear[]>([]);

  constructor(
    private http: HttpClient,
  ) {
    this.loadClasses();
  }

  getClasses(): Observable<SchoolClassYear[]> {
    return this.classes.asObservable();
  }

  private loadClasses() {
    this.http.get<SchoolClassYear[]>(`${AppConfig.API_ENDPOINT}/admin/classes`)
      .subscribe(classes => this.classes.next(classes));
  }

  getClass(id: string): Observable<SchoolClassYear> {
    return this.http.get<SchoolClassYear>(`${AppConfig.API_ENDPOINT}/admin/classes/${id}`);
  }

  createClassYear(formData): Observable<{ name: string }> {
    return this.http.post<{ name: string }>(`${AppConfig.API_ENDPOINT}/admin/classes`, formData);
  }

  updateClassYear(formData): Observable<void> {
    return this.http.patch<void>(`${AppConfig.API_ENDPOINT}/admin/classes`, formData);
  }

  updateOrder(ids: string[]): Observable<void> {
    return this.http.patch<void>(`${AppConfig.API_ENDPOINT}/admin/classes/order`, ids);
  }
}
