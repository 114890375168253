import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageService} from '../../../shared/services/message.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminSubjectService} from '../../shared/services/admin-subject.service';

@Component({
  selector: 'app-subject-panel',
  templateUrl: './admin-subject-settings.component.html',
  styleUrls: ['./admin-subject-settings.component.css']
})
export class AdminSubjectSettingsComponent implements OnInit {
  private form: FormGroup;

  constructor(
    private subjectsService: AdminSubjectService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      displayName: ['', Validators.required],
      name: ['', Validators.required],
      colors: [[]],
      topics: [[]],
    });

    const id = this.route.snapshot.paramMap.get('subjectId');
    if (id) {
      this.subjectsService.getSubject(id).subscribe(subject => {
        this.form.patchValue(subject);
      });
    }
  }


  saveChanges() {
    const data = this.form.value;

    const subjectId = this.route.snapshot.paramMap.get('subjectId');
    if (subjectId) {
      this.subjectsService.updateSubject(subjectId, data).subscribe(() => {
        this.messageService.showMessage('Änderungen wurden erfolgreich gespeichert');
        this.router.navigate(['admin/subjects']);
      });
    } else {
      this.subjectsService.addSubject(data).subscribe(() => {
        this.messageService.showMessage('Fach wurde erfolgreich erstellt');
        this.router.navigate(['admin/subjects']);
      });
    }
  }

}
