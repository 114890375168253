import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../app-config';
import {UserRole} from '../models/user-role';

@Injectable({
  providedIn: 'root'
})
export class AdminUserRoleService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getAll(): Observable<UserRole[]> {
    return this.http.get<UserRole[]>(`${AppConfig.API_ENDPOINT}/admin/user-roles`);
  }

  getDetails(id: string): Observable<{ name: string, permissions: { permissionKey: string, allowedFor: string }[] }> {
    return this.http.get<{
      name: string,
      permissions: { permissionKey: string, allowedFor: string }[]
    }>(`${AppConfig.API_ENDPOINT}/admin/user-roles/${id}`);
  }

  create(formData): Observable<UserRole> {
    return this.http.post<UserRole>(`${AppConfig.API_ENDPOINT}/admin/user-roles`, formData);
  }

  update(id: string, formData): Observable<void> {
    return this.http.patch<void>(`${AppConfig.API_ENDPOINT}/admin/user-roles/${id}`, formData);
  }

  updateDefault(id: string): Observable<void> {
    return this.http.patch<void>(`${AppConfig.API_ENDPOINT}/admin/user-roles/default/${id}`, {});
  }
}
