import {Component, Input, OnInit} from '@angular/core';
import {UserProfileSkill} from '../../shared/models/user-skill';

@Component({
  selector: 'app-profile-skills',
  templateUrl: './profile-skills.component.html',
  styleUrls: ['./profile-skills.component.css']
})
export class ProfileSkillsComponent implements OnInit {

  @Input() skills: UserProfileSkill[];

  skillsAbilityLabels = [
    'Gut',
    'Sehr gut',
    'Ausgezeichnet',
  ];

  constructor() {
  }

  ngOnInit() {
  }

}
