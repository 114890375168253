import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../../shared/models/user';
import {MessageService} from '../../../shared/services/message.service';
import {AdminPostService} from '../../shared/services/admin-post.service';

@Component({
  selector: 'app-admin-user-delete-posts-dialog',
  templateUrl: './admin-user-delete-posts-dialog.component.html',
  styleUrls: ['./admin-user-delete-posts-dialog.component.css']
})
export class AdminUserDeletePostsDialogComponent implements OnInit {
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<AdminUserDeletePostsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      user: User,
    },
    private postService: AdminPostService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
  }

  deletePosts() {
    this.isLoading = true;
    this.postService.deleteByUser(this.data.user.id).subscribe(() => {
      this.messageService.showMessage('Es wurden alle Nutzer-Posts gelöscht!');
      this.dialogRef.close(true);
    }, () => {
      this.isLoading = false;
      this.messageService.showMessage('Beim Löschen der Nutzer-Posts ist ein Fehler aufgetreten.');
    });
  }

}
