import {Component, OnInit} from '@angular/core';
import {ResetPasswordService} from '../../shared/services/reset-password.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {validatePassword} from '../../../shared/validators/password.validator';
import {passwordMatchValidator} from '../../../shared/validators/password-match.validator';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../../../shared/services/message.service';

@Component({
  selector: 'app-reset-password-change',
  templateUrl: './reset-password-change.component.html',
  styleUrls: ['./reset-password-change.component.css']
})
export class ResetPasswordChangeComponent implements OnInit {
  isLoading = true;
  passwordHide = true;

  formGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private resetPasswordService: ResetPasswordService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      password: ['', [Validators.required, validatePassword]],
      confirmPassword: ['', Validators.required],
    }, {validators: passwordMatchValidator});

    const token = this.route.snapshot.queryParams.token;
    if (!token) {
      this.router.navigate(['/login']);
    } else {
      this.resetPasswordService.isTokenValid(token).subscribe(() => {
        this.isLoading = false;
      }, () => {
        this.messageService.showMessage('Der Token ist ungültig. Womöglich ist er abgelaufen?');
        this.router.navigate(['/login']);
      });
    }
  }

  onSubmit() {
    const token = this.route.snapshot.queryParams.token;
    this.resetPasswordService.changePassword(token, this.formGroup.value.password).subscribe(() => {
      this.messageService.showMessage('Dein Passwort wurde erfolgreich geändert! Du kannst dich nun wieder einloggen.');
      this.router.navigate(['/login']);
    }, () => {
      this.messageService.showMessage('Leider konnte dein Passwort nicht geändert werden. Bitte versuche es später erneut!');
      this.router.navigate(['/password-reset']);
    });
  }

}
