import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formatting'
})
export class FormattingPipe implements PipeTransform {

  transform(content: string, showHints = true): any {
    // Fügt Leerzeichen an die Ränder, damit die Erkennung der Formatierung auch am Anfang und Ende funktioniert
    content = ' ' + content + ' ';
    content = content
      // Ersetzt alle <> um HTML-Einbindung zu verhindern
      .replace(/</g, '&lt;').replace(/>/g, '&gt;')
      // Wandelt neue Zeilen in HTML um
      .replace(/\n/g, '<br>')
      // Ersetzt URLs durch anklickbare Links
      .replace(/https?:\/\/(www\.)?([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))/g, ($0, _, $2) => `<a href="${$0}" target="_blank">${$2}</a>`)
      // Formatiert Text zwischen Sternchen (*) fett
      .replace(/ \*([^*]+)\* /g, ($0, $1) => ` ${this.getStylingHint('*', showHints)}<b>${$1}</b>${this.getStylingHint('*', showHints)} `)
      // Formatiert Text zwischen Slashes (\ oder /) kursiv
      .replace(/ ((\\)([^\\]+)(\\))|((\/)([^/]+)(\/)) /g, ($0, _, $1, $2, $3, __, $4, $5, $6) => ` ${this.getStylingHint($1 || $4, showHints)}<i>${$2 || $5}</i>${this.getStylingHint($3 || $6, showHints)} `)
      // Formatiert Text zwischen Unterstrichen (_) unterstrichen
      .replace(/ _([^_]+)_ /g, ($0, $1) => ` ${this.getStylingHint('_', showHints)}<span class="underlined">${$1}</span>${this.getStylingHint('_', showHints)} `)
      // Formatiert Text zwischen Tilden (~) durchgestrichen
      .replace(/ ~([^~]+)~ /g, ($0, $1) => ` ${this.getStylingHint('~', showHints)}<span class="strikethrough">${$1}</span>${this.getStylingHint('~', showHints)} `);
    content = content.trim();
    return content;
  }

  getStylingHint(inner: string, showHints: boolean): string {
    if (!showHints) {
      return '';
    }
    return `<span class="styling-hint">${inner}</span>`;
  }

}
