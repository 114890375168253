import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../../app-config';
import {PostType, UserPost} from '../../../shared/models/user-post';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {UserPostEvent} from '../../../shared/models/user-post-event';
import {UserPostAttachment} from '../models/user-post-attachment';
import {SubjectsService} from '../../../shared/services/subjects.service';
import {FeedService} from './feed.service';
import {AuthenticationService} from '../../../shared/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PostService {
  private newestPosts = new BehaviorSubject<UserPost[]>([]);

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private feedService: FeedService,
    private subjectService: SubjectsService,
  ) {
    // Lädt neuesten Beiträge alle 10 Minuten
    if (this.authenticationService.currentUserValue) {
      this.loadNewestPosts();
    } else {
      this.authenticationService.currentUser.subscribe(user => {
        if (user) {
          this.loadNewestPosts();
        }
      });
    }
    setInterval(() => {
      this.loadNewestPosts();
    }, 600000);
  }

  addPost(formData): Observable<any> {
    const data = {
      subjectId: formData.subject,
      topicId: formData.topic ? formData.topic : undefined,
      type: formData.type,
      title: formData.title,
      description: formData.details,
      attachments: formData.attachments ? formData.attachments : undefined,
      dateDue: formData.dateDue ? formData.dateDue.getTime() : undefined,
    };
    return this.http.post<any>(`${AppConfig.API_ENDPOINT}/posts`, data)
      .pipe(tap(() => {
        this.resetNewestPosts();
        this.feedService.reset();
      }));
  }

  updatePost(id: string, formData): Observable<void> {
    const data = {
      subjectId: formData.subject,
      topicId: formData.topic ? formData.topic : undefined,
      title: formData.title,
      description: formData.details,
      attachments: formData.attachments ? formData.attachments : undefined,
      dateDue: formData.dateDue ? formData.dateDue.getTime() : undefined,
    };
    return this.http.patch<void>(`${AppConfig.API_ENDPOINT}/posts/${id}`, data)
      .pipe(tap(() => {
        this.resetNewestPosts();
        this.feedService.reset();
      }));
  }

  getPostById(id: string): Observable<UserPost> {
    return this.http.get<UserPost>(`${AppConfig.API_ENDPOINT}/posts/${id}`)
      .pipe(map(post => {
        post.type = PostType.getByKey(post.type);
        return post;
      }));
  }

  getPostAttachments(id: string): Observable<UserPostAttachment[]> {
    return this.http.get<UserPostAttachment[]>(`${AppConfig.API_ENDPOINT}/post/${id}/attachments`);
  }

  getPostAttachmentFiles(id: string): Observable<{ id: string, name: string, type: string, size: number }[]> {
    return this.http.get<{ id: string, name: string, type: string, size: number }[]>(`${AppConfig.API_ENDPOINT}/post/${id}/attachments/files`);
  }

  getPostEvents(id: string): Observable<UserPostEvent[]> {
    return this.http.get<UserPostEvent[]>(`${AppConfig.API_ENDPOINT}/posts/chronic/${id}`);
  }

  getNewestPosts(): Observable<UserPost[]> {
    return this.newestPosts.asObservable();
  }

  private resetNewestPosts() {
    this.newestPosts.next([]);
  }

  private loadNewestPosts() {
    this.searchPosts(null, {sortBy: 'dateCreated', desc: true}, 0, 5)
      .subscribe(posts => this.newestPosts.next(posts));
  }

  getOwnPosts(offset?: number, count?: number): Observable<UserPost[]> {
    return this.http.get<UserPost[]>(`${AppConfig.API_ENDPOINT}/posts/own?count=${count}&offset=${offset}`)
      .pipe(map(posts => {
        return posts.map(post => {
          post.type = PostType.getByKey(post.type);
          // @ts-ignore
          post.subject = this.subjectService.getSubjectValue(post.subject);
          return post;
        });
      }));
  }

  searchPosts(filters?: { [key: string]: any }, sort?: { sortBy: string, desc: boolean }, offset?: number, count?: number): Observable<UserPost[]> {
    const paramsRaw: { [key: string]: any } = {};
    if (filters) {
      paramsRaw.filter = filters;
    }
    if (sort) {
      paramsRaw.sortBy = sort.sortBy;
      paramsRaw.desc = sort.desc;
    }
    if (offset) {
      paramsRaw.offset = offset;
    }
    if (count) {
      paramsRaw.count = count;
    }
    const params = cleanUp(paramsRaw);
    let param = getUrlString(params);
    if (param.length > 0) {
      param = '?' + param;
    }

    return this.http.get<UserPost[]>(`${AppConfig.API_ENDPOINT}/posts${param}`)
      .pipe(map(posts => {
        return posts.map(post => {
          post.type = PostType.getByKey(post.type);
          // @ts-ignore
          post.subject = this.subjectService.getSubjectValue(post.subject);
          return post;
        });
      }));
  }

  reportPost(postId: string, report: { reportType: string, reportText?: string }): Observable<void> {
    return this.http.post<void>(`${AppConfig.API_ENDPOINT}/posts/${postId}/report`, report);
  }

  closePost(postId: string): Observable<void> {
    return this.http.patch<void>(`${AppConfig.API_ENDPOINT}/posts/${postId}/archive`, {});
  }

  deletePost(postId: string): Observable<void> {
    return this.http.delete<void>(`${AppConfig.API_ENDPOINT}/posts/${postId}`)
      .pipe(tap(() => {
        this.resetNewestPosts();
        this.feedService.reset();
      }));
  }
}

export function cleanUp(object: object): object {
  const clone = {};
  Object.keys(object).forEach(key => {
    const value = object[key];

    if (value === undefined || value === null || (value.length !== undefined && value.length === 0)) {
      return;
    }

    if ('[object Object]' === Object.prototype.toString.call(value)) {
      clone[key] = cleanUp(value);
    } else {
      clone[key] = value;
    }
  });
  return clone;
}

export function getUrlString(params, keys = [], isArray = false) {
  const p = Object.keys(params).map(key => {
    const val = params[key];

    if ('[object Object]' === Object.prototype.toString.call(val) || Array.isArray(val)) {
      if (Array.isArray(params)) {
        keys.push('');
      } else {
        keys.push(key);
      }
      return getUrlString(val, keys, Array.isArray(val));
    } else {
      let tKey = key;

      if (keys.length > 0) {
        const tKeys = isArray ? keys : [...keys, key];
        tKey = tKeys.reduce((str, k) => '' === str ? k : `${str}[${k}]`, '');
      }
      if (isArray) {
        return `${tKey}[]=${val}`;
      } else {
        return `${tKey}=${val}`;
      }

    }
  }).join('&');

  keys.pop();
  return p;
}
