import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {User} from '../../../shared/models/user';
import {ChatMessageService} from '../shared/services/chat-message.service';
import {SocketService} from '../shared/services/socket.service';

@Component({
  selector: 'app-chat-message-list',
  templateUrl: './chat-message-list.component.html',
  styleUrls: ['./chat-message-list.component.css'],
})
export class ChatMessageListComponent implements OnInit {
  @Input() userSelf: User;
  private userContactValue: User;

  @Input()
  set userContact(value) {
    this.userContactValue = value;
    if (value) {
      this.init();
    }
  }

  @ViewChild('scrollContainer', {static: true}) scrollContainer: ElementRef;

  scrollTop = 0;
  private isLoading = false;
  private lastLoading = 0;

  private messageGroups = [];
  private prevMessageId: string;

  openMenu;

  constructor(
    private messageService: ChatMessageService,
    private socketService: SocketService,
  ) {
  }

  ngOnInit() {
  }

  init() {
    this.messageService.getContactMessages(this.userContactValue).subscribe(result => {
      console.log(result);
      this.prevMessageId = result.lastMessageId;
      this.messageGroups = result.messages;
      result.unreadMessages.forEach(message => this.socketService.markAsRead(message));
    });
    this.loadItems(10);
    this.listenScrollHeight();
  }

  loadItems(count: number = 10) {
    this.isLoading = true;
    this.messageService.loadContactMessages(this.userContactValue, count, this.prevMessageId).subscribe(messages => {
      this.isLoading = false;
      this.lastLoading = Date.now();
      if (messages.length > 0) {
        this.checkWindowSize();
      }
    });
  }

  listenScrollHeight() {
    const el = this.scrollContainer.nativeElement;
    let lastHeight = el.scrollHeight;
    let newHeight;
    const run = () => {
      newHeight = el.scrollHeight;
      if (lastHeight !== newHeight) {
        this.scrollTop += newHeight - lastHeight;
        lastHeight = newHeight;
      }

      if (el.onElementHeightChangeTimer) {
        clearTimeout(el.onElementHeightChangeTimer);
      }
      el.onElementHeightChangeTimer = setTimeout(run, 200);
    };
    run();
  }

  onScroll() {
    const el = this.scrollContainer.nativeElement;

    this.scrollTop = el.scrollTop;
    if (el.scrollTop < 150 && !this.isLoading && Date.now() - this.lastLoading > 1000) {
      this.loadItems();
    }
  }

  private checkWindowSize() {
    setTimeout(() => {
      const containerHeight = this.scrollContainer.nativeElement.clientHeight;
      const messagesHeight = this.messageGroups.map(group => group.messages ? group.messages.length * 52 : 50).reduce((a, b) => a + b);
      if (messagesHeight < containerHeight) {
        this.loadItems();
      }
    }, 500);
  }

}

