import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {AdminUserRoleService} from '../shared/services/admin-user-role.service';
import {MessageService} from '../../shared/services/message.service';

@Component({
  selector: 'app-admin-user-groups',
  templateUrl: './admin-user-roles.component.html',
  styleUrls: ['./admin-user-roles.component.css']
})
export class AdminUserRolesComponent implements OnInit {
  displayedColumns = ['name', 'members', 'permissions', 'default'];
  dataSource = new MatTableDataSource();

  constructor(
    private router: Router,
    private userRoleService: AdminUserRoleService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
    this.userRoleService.getAll().subscribe(roles => {
      this.dataSource.connect().next(roles);
    });
  }

  onRowClick(event, row) {
    if (event.target.tagName === 'TD') {
      this.router.navigate(['/admin/users/roles/' + row.id]);
    }
  }

  onChangeDefault(event) {
    this.userRoleService.updateDefault(event.value).subscribe(() => {
      this.messageService.showMessage('Standard-Rolle wurde gespeichert!');
    }, () => {
      this.messageService.showMessage('Leider ist beim Speichern der Standard-Rolle ein Fehler aufgetreten.');
    });
  }
}
