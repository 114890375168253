import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {AppConfig} from '../app-config';
import {FileProgress} from './FileProgress';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(
    private http: HttpClient,
  ) {
  }

  public upload(file: File, url = 'post/attachment'): FileProgress {
    const formData = new FormData();
    formData.append('file', file, file.name);

    const request = new HttpRequest('POST', `${AppConfig.API_ENDPOINT}/${url}`, formData, {
      reportProgress: true,
    });

    const progress = new Subject<number>();
    const error = new Subject<Error>();
    const result = new Subject<{ id: string, type: string, size: number }>();

    this.http.request(request).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        const percentDone = Math.round(100 * event.loaded / event.total);

        progress.next(percentDone);
      } else if (event instanceof HttpResponse) {
        progress.complete();
        // @ts-ignore
        result.next(event.body);
        result.complete();
      }
    }, err => {
      error.next(err);
    });

    return {
      progress: progress.asObservable(),
      error: error.asObservable(),
      result: result.asObservable(),
    };
  }
}
