import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {AppConfig} from '../../../app-config';
import {PostType, UserPost} from '../../../shared/models/user-post';
import {map, tap} from 'rxjs/operators';
import {SubjectsService} from '../../../shared/services/subjects.service';

@Injectable({
  providedIn: 'root'
})
export class FeedService {
  private posts = new BehaviorSubject<UserPost[]>([]);
  private atEnd = false;

  constructor(
    private http: HttpClient,
    private subjectService: SubjectsService,
  ) {
  }

  getFeed(): Observable<UserPost[]> {
    return this.posts.asObservable();
  }

  isAtEnd(): boolean {
    return this.atEnd;
  }

  reset() {
    this.posts.next([]);
    this.atEnd = false;
  }

  loadFeed(offset = 0, count = 10): Observable<UserPost[]> {
    if (this.atEnd) {
      return;
    }

    this.http.get<UserPost[]>(`${AppConfig.API_ENDPOINT}/posts/feed?count=${count}&offset=${offset}`)
      .pipe(map(posts => {
          return posts.map(post => {
            post.type = PostType.getByKey(post.type);
            // @ts-ignore
            post.subject = this.subjectService.getSubjectValue(post.subject);
            return post;
          });
        }),
        tap(posts => {
          if (posts.length < count) {
            this.atEnd = true;
          }
        }))
      .subscribe(posts => this.posts.next(this.posts.value.concat(...posts)));
    return this.posts;
  }
}
