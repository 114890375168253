import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {PostType, UserPost} from '../../shared/models/user-post';
import {PostService} from '../shared/services/post.service';
import {Subject} from '../../shared/models/subject';
import {SubjectsService} from '../../shared/services/subjects.service';
import {MatDialog} from '@angular/material/dialog';
import {ContactDialogComponent} from './contact-dialog/contact-dialog.component';
import {UserPostEvent} from '../../shared/models/user-post-event';
import {TimelineEntry} from './timeline/timeline.component';
import {UserPostAttachment} from '../shared/models/user-post-attachment';
import {MessageService} from '../../shared/services/message.service';
import {CloseDialogComponent} from './close-dialog/close-dialog.component';
import {DeleteDialogComponent} from './delete-dialog/delete-dialog.component';
import {ReportDialogComponent} from './report-dialog/report-dialog.component';

@Component({
  selector: 'app-post-details',
  templateUrl: './post-details.component.html',
  styleUrls: ['./post-details.component.css']
})
export class PostDetailsComponent implements OnInit {
  post: UserPost;
  subject: Subject;
  attachments: UserPostAttachment[];
  events: UserPostEvent[];

  headerBarVisible = false;
  isContactAvailable = false;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private postService: PostService,
    private subjectsService: SubjectsService,
    private messageService: MessageService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.getPost();
  }

  onScroll() {
    const el = document.querySelector('.header');
    const elBottom = el.getBoundingClientRect().bottom;

    this.headerBarVisible = elBottom < 100;
  }

  getGradient() {
    return `linear-gradient(90deg, ${this.subject.colors})`;
  }

  getPost() {
    const id = this.route.snapshot.paramMap.get('id');
    this.postService.getPostById(id).subscribe(post => {
      this.post = post;
      this.getSubject();
      this.getPostAttachments(post.id);
      this.getPostEvents(post.id);

      this.isContactAvailable = post.type === PostType.REQUEST || post.type === PostType.OFFER;

      if (post.isArchived) {
        this.isContactAvailable = false;
      }
    }, error => {
      if (error.statusCode === 404) {
        this.messageService.showMessage('Der angegebene Post existiert nicht. Womöglich wurde er zwischenzeitlich gelöscht.');
      } else {
        this.messageService.showMessage('Dieser Post konnte nicht geladen werden. Es ist ein Fehler aufgetreten.');
      }
    });
  }

  getPostAttachments(id: string) {
    this.postService.getPostAttachments(id).subscribe(attachments => {
      this.attachments = attachments;
    });
  }

  getPostEvents(id: string) {
    this.postService.getPostEvents(id).subscribe(events => {
      this.events = events;
    });
  }

  getSubject() {
    this.subjectsService.getSubject(this.post.subject.id).subscribe(subject => this.subject = subject);
  }

  getTimelineEntries(): TimelineEntry[] {
    return this.events.map(event => {
      let title = '';
      switch (event.type) {
        case 'CREATE':
          title = 'Post wurde erstellt';
          break;
        case 'EDIT':
          title = 'Post wurde bearbeitet';
          break;
        case 'NEW_RESPONSE':
          title = 'Ein Nutzer hat geantwortet';
          break;
        case 'ACCEPT_RESPONSE':
          title = 'Der Autor hat mit einem Nutzer Kontakt aufgenommen';
          break;
        case 'CLOSE':
          title = 'Der Post wurde abgeschlossen.';
          break;
      }
      return {
        type: event.type,
        title,
        date: event.datetime,
      };
    });
  }

  showContactDialog() {
    const dialogRef = this.dialog.open(ContactDialogComponent, {
      width: '450px',
      data: {
        post: this.post,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPostEvents(this.route.snapshot.paramMap.get('id'));
      }
    });
  }

  showReportDialog() {
    const dialogRef = this.dialog.open(ReportDialogComponent, {
      width: '450px',
      data: {
        post: this.post,
      },
    });
  }

  showClosePostDialog() {
    const dialogRef = this.dialog.open(CloseDialogComponent, {
      width: '450px',
      data: {
        post: this.post,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.post.isArchived = true;
        this.getPostEvents(this.route.snapshot.paramMap.get('id'));
      }
    });
  }

  showDeleteDialog() {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '450px',
      data: {
        post: this.post,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/']);
      }
    });
  }

  back() {
    this.location.back();
  }
}
