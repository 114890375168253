import {Component, Input, OnInit} from '@angular/core';
import {ChatConversation} from '../../shared/services/contact.service';
import {MatDialog} from '@angular/material/dialog';
import {ChatConversationRatingDialogComponent} from '../chat-conversation-rating-dialog/chat-conversation-rating-dialog.component';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {User} from '../../../shared/models/user';

@Component({
  selector: 'app-contact-post-item',
  templateUrl: './chat-conversation-item.component.html',
  styleUrls: ['./chat-conversation-item.component.css']
})
export class ChatConversationItemComponent implements OnInit {
  @Input() conversation: ChatConversation;

  self: User;

  constructor(
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
  ) {
    this.self = authenticationService.currentUserValue;
  }

  ngOnInit() {
  }

  showRatingDialog(action: boolean) {
    const dialog = this.dialog.open(ChatConversationRatingDialogComponent, {
      data: {
        conversation: this.conversation,
        action,
      }
    });
    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.conversation.status = action ? 'COMPLETED' : 'CANCELLED';
      }
    });
  }

}
