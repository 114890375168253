import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {AdminPostService} from '../shared/services/admin-post.service';
import {MatPaginator} from '@angular/material/paginator';
import {UserPost} from '../../shared/models/user-post';
import {MatSort} from '@angular/material/sort';
import {BehaviorSubject, merge} from 'rxjs';
import {map, startWith, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-admin-posts',
  templateUrl: './admin-posts.component.html',
  styleUrls: ['./admin-posts.component.css']
})
export class AdminPostsComponent implements OnInit, AfterViewInit {
  displayedColumns = ['title', 'subject', 'topic', 'class', 'user', 'responses', 'dateCreated'];

  data: UserPost[] = [];
  resultsLength = 0;
  isLoading = true;

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  private filter = new BehaviorSubject<string>(undefined);
  private filterTimeout;

  constructor(
    private postService: AdminPostService,
  ) {
  }

  ngOnInit() {
    this.postService.getCount().subscribe(count => this.resultsLength = count);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page, this.filter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          return this.postService.getPosts(this.sort.active, this.sort.direction === 'desc', this.paginator.pageIndex, this.filter.getValue());
        }),
        map(data => {
          this.isLoading = false;

          return data;
        })
      ).subscribe(data => this.data = data);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    clearTimeout(this.filterTimeout);
    this.filterTimeout = setTimeout(() => {
      this.filter.next(filterValue.trim());
    }, 500);
  }

}
