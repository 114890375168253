import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BytesPipe} from '../pipes/bytes.pipe';
import {EllipsisPipe} from '../pipes/ellipsis.pipe';
import {FileIconsPipe} from '../pipes/file-icons.pipe';
import {FormattingPipe} from '../pipes/formatting.pipe';


@NgModule({
  declarations: [
    BytesPipe,
    EllipsisPipe,
    FileIconsPipe,
    FormattingPipe,
  ],
  exports: [
    BytesPipe,
    EllipsisPipe,
    FileIconsPipe,
    FormattingPipe,
  ],
  imports: [
    CommonModule
  ]
})
export class PipeModule {
}
