import {Component, Input, OnInit} from '@angular/core';
import {UserPost} from '../../../shared/models/user-post';
import {Subject} from '../../../shared/models/subject';
import {Router} from '@angular/router';

@Component({
  selector: 'app-post',
  templateUrl: './post-entry.component.html',
  styleUrls: ['./post-entry.component.css']
})
export class PostEntryComponent implements OnInit {
  @Input() post: UserPost;

  constructor(
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  getGradient(): string {
    return Subject.gradient(this.post.subject.colors);
  }

  openPost() {
    this.router.navigate(['/posts/details/' + this.post.id]);
  }

  openSubject() {
    this.router.navigate(['/posts'], {queryParams: {subjects: this.post.subject.name}});
  }
}
