import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      console.log(err);
      if (this.authenticationService.currentUserValue && err.status === 401) {
        this.authenticationService.logout();
        location.reload();
        return;
      }

      return throwError(err.error.error || {
        statusCode: 0,
        name: 'ConnectionFailed',
        message: 'SERVER_CONNECTION_FAILED',
      });
    }));
  }
}
