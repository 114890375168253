import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../shared/services/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  loggedIn: boolean;

  constructor(
    private authenticationService: AuthenticationService,
  ) {
    this.loggedIn = !!authenticationService.currentUserValue;
  }

  ngOnInit() {
  }

}
