import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../../../shared/models/user';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../../app-config';
import {PostResponse} from '../models/post-response';
import {UserPost} from '../../../shared/models/user-post';
import {map, tap} from 'rxjs/operators';
import {SocketService} from '../../messages/shared/services/socket.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private contacts = new BehaviorSubject<User[]>([]);

  constructor(
    private http: HttpClient,
    private socketService: SocketService,
  ) {
    this.loadContacts();

    // Aktualisiert Kontakte, sobald sie server-seitig aktualisiert wurden
    this.socketService.onContactUpdate().subscribe(() => {
      this.loadContacts();
    });
  }

  loadContacts() {
    this.http.get<User[]>(`${AppConfig.API_ENDPOINT}/users/contacts`)
      .pipe(map(users => users.map(user => {
        user.profilePicture = `${AppConfig.API_ENDPOINT}/users/profile-picture/${user.profilePicture}`;
        return user;
      })))
      .subscribe(contacts => this.contacts.next(contacts));
  }

  getContacts(): Observable<User[]> {
    return this.contacts.asObservable();
  }

  removeContact(contactUserId: string): Observable<void> {
    return this.http.delete<void>(`${AppConfig.API_ENDPOINT}/users/contacts/${contactUserId}`)
      .pipe(tap(() => {
        this.loadContacts();
      }));
  }
}

export class ChatConversation {
  id: string;
  isHelper: boolean;
  user: User;
  post: UserPost;
  response: PostResponse;
  status: string;
  dateCreated: string;
}
