import {Component, Input, OnInit} from '@angular/core';
import {PostResponse} from '../../shared/models/post-response';

@Component({
  selector: 'app-contact-post-list',
  templateUrl: './chat-conversation-list.component.html',
  styleUrls: ['./chat-conversation-list.component.css']
})
export class ChatConversationListComponent implements OnInit {
  @Input() responses: PostResponse[];

  constructor() {
  }

  ngOnInit() {
  }

}
