import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../../shared/models/user';
import {MessageService} from '../../../shared/services/message.service';
import {ContactService} from '../../shared/services/contact.service';

@Component({
  selector: 'app-remove-contact-confirm-dialog',
  templateUrl: './remove-contact-confirm-dialog.component.html',
  styleUrls: ['./remove-contact-confirm-dialog.component.css']
})
export class RemoveContactConfirmDialogComponent implements OnInit {
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<RemoveContactConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      contact: User,
    },
    private contactService: ContactService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
  }

  removeContact() {
    this.isLoading = true;
    this.contactService.removeContact(this.data.contact.id).subscribe(() => {
      this.messageService.showMessage('Der Kontakt wurde erfolgreich beendet.');
      this.dialogRef.close(true);
    }, error => {
      this.isLoading = false;
      if (error.message === 'ACTIVE_CONVERSATIONS_REMAINING') {
        this.messageService.showMessage('Bitte beende erst alle bestehenden Konversationen mit diesem Nutzer, bevor du den Kontakt beenden kannst.');
        this.dialogRef.close();
      } else {
        this.messageService.showMessage('Beim Beenden des Kontakts ist ein Fehler aufgetreten!');
      }
    });
  }

}
