import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icon-details',
  templateUrl: './icon-details.component.html',
  styleUrls: ['./icon-details.component.css']
})
export class IconDetailsComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() icon: string;

  constructor() {
  }

  ngOnInit() {
  }

}
