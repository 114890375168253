import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FeedbackService} from '../shared/services/feedback.service';
import {MessageService} from '../../shared/services/message.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.css']
})
export class FeedbackDialogComponent implements OnInit {
  form: FormGroup;
  isLoading = false;

  constructor(
    private dialogRef: MatDialogRef<FeedbackDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      route: string,
    },
    private fb: FormBuilder,
    private feedbackService: FeedbackService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      message: ['', Validators.required],
    });
  }

  send() {
    this.isLoading = true;
    const value = this.form.value;
    this.feedbackService.sendFeedback(value.message.trim(), this.data.route).subscribe(() => {
      this.messageService.showMessage('Vielen Dank für dein Feedback!');
      this.dialogRef.close();
    }, () => {
      this.messageService.showMessage('Beim Senden deines Feedbacks ist leider ein Fehler aufgetreten.');
    });
  }

}
