import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageService} from '../../../shared/services/message.service';
import {UserSettingsService} from '../../shared/services/user-settings.service';

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.css']
})
export class EmailSettingsComponent implements OnInit {
  emailForm: FormGroup;
  wasEmailChanged = false;
  isLoading = true;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private settingsService: UserSettingsService,
  ) {
  }

  ngOnInit() {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.settingsService.settings.subscribe(settings => {
      this.emailForm.patchValue(settings);
      this.isLoading = false;

      this.emailForm.valueChanges.subscribe(value => this.wasEmailChanged = value.email !== settings.email);
    });
  }

  saveEmail() {
    if (!this.wasEmailChanged) {
      return;
    }

    this.settingsService.saveEmail(this.emailForm.value.email).subscribe(() => {
      this.isLoading = false;
      this.messageService.showMessage('Deine E-Mail-Adresse wurde gespeichert. Bitte bestätige sie, damit die Änderung inkraft tritt');
    }, error => {
      this.isLoading = false;
      switch (error.message) {
        case 'ALREADY_USED':
          this.messageService.showMessage('Die angegebene E-Mail-Adresse ist bereits in Verwendung.');
          break;
        case 'SAME_ADDRESS':
          this.messageService.showMessage('Du hast diese E-Mail-Adresse bereits.');
          break;
        default:
          this.messageService.showMessage('Leider konnte deine E-Mail-Adresse nicht gespeichert werden.');
          break;
      }
    });

  }
}
