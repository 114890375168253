export class Subject {
  id: string;
  name: string;
  displayName: string;
  colors: string[];

  constructor() {
  }

  static gradient(colors): string {
    return `linear-gradient(90deg, ${colors})`;
  }
}
