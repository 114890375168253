import {AbstractControl} from '@angular/forms';

export function validateUsername(control: AbstractControl) {
  if (control.value.length === 0) {
    return null;
  }

  const errors: {
    usernameTooShort?: boolean,
    usernameTooLong?: boolean,
    usernameInvalid?: boolean,
  } = {};

  if (control.value.length < 4) {
    errors.usernameTooShort = true;
  }

  if (control.value.length > 16) {
    errors.usernameTooLong = true;
  }

  const regex = /[^a-zA-Z0-9.\-_+]/g;
  const match = control.value.match(regex);

  if (match) {
    errors.usernameInvalid = true;
  }

  return Object.keys(errors).length > 0 ? errors : null;
}
