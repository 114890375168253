import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AdminClassService} from '../shared/services/admin-class.service';
import {moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-admin-classes',
  templateUrl: './admin-classes.component.html',
  styleUrls: ['./admin-classes.component.css']
})
export class AdminClassesComponent implements OnInit {
  private displayedColumns = ['handle', 'index', 'name', 'classes'];
  private dataSource = [];
  private originalData = [];

  @ViewChild('table', {static: true}) table: ElementRef;

  constructor(
    private classesService: AdminClassService,
  ) {
  }

  ngOnInit() {
    this.classesService.getClasses().subscribe(classes => {
      this.dataSource = classes;
      this.originalData = classes;
    });
  }

  getClassesString(classes): string {
    return classes.map(c => c.name).join(', ');
  }

  onDrop(event) {
    let array = this.dataSource;
    const prevIndex = this.dataSource.findIndex(item => item === event.item.data);
    moveItemInArray(array, prevIndex, event.currentIndex);
    array = array.map((item, index) => {
      item.orderPosition = index + 1;
      return item;
    });
    this.dataSource = array;
    // this.table.renderRows();

    this.classesService.updateOrder(this.dataSource.map(item => item.id)).subscribe(() => {
    });
  }

}
