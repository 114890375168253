import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {User} from '../../shared/models/user';
import {AdminUserService} from '../shared/services/admin-user.service';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {BehaviorSubject, merge} from 'rxjs';
import {map, startWith, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent implements OnInit, AfterViewInit {
  displayedColumns = ['name', 'username', 'role', 'class', 'post_count', 'joined_date', 'status'];

  data: User[] = [];
  resultsLength = 0;
  isLoading = true;

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  private filter = new BehaviorSubject<string>(undefined);
  private filterTimeout;

  constructor(
    private userService: AdminUserService,
  ) {
  }

  ngOnInit() {
    this.userService.getCount().subscribe(count => this.resultsLength = count);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page, this.filter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          return this.userService.getUsers(this.sort.active, this.sort.direction === 'desc', this.paginator.pageIndex, this.filter.getValue());
        }),
        map(data => {
          this.isLoading = false;

          return data;
        })
      ).subscribe(data => this.data = data);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    clearTimeout(this.filterTimeout);
    this.filterTimeout = setTimeout(() => {
      this.filter.next(filterValue.trim());
    }, 500);
  }

  getStatusText(status: string) {
    switch (status) {
      case 'ACTIVE':
        return 'Aktiviert';
      case 'NEEDS_VERIFICATION':
        return 'E-Mail-Verifizierung ausstehend';
      case 'BANNED':
        return 'Account gesperrt';
      default:
        return '';
    }
  }
}
