import {Component, forwardRef, OnInit} from '@angular/core';
import permissionsList from '../shared/data/permissions.json';
import {ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PermissionsComponent),
      multi: true,
    },
  ]
})
export class PermissionsComponent implements OnInit, ControlValueAccessor {
  formGroup: FormGroup;
  displayedColumns = ['name', 'view-perm', 'create-perm', 'write-perm', 'delete-perm'];
  permissionsList = permissionsList;

  private propagateChange = (_: any) => {
  };

  constructor(
    private fb: FormBuilder,
  ) {
  }

  get formArray(): FormArray {
    return this.formGroup.controls.permissions as FormArray;
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      permissions: this.fb.array(this.permissionsList.map(() => {
        return this.fb.group({
          view: this.fb.control('None'),
          create: this.fb.control('None'),
          write: this.fb.control('None'),
          delete: this.fb.control('None'),
        });
      })),
    });

    this.formArray.valueChanges.subscribe(value => {
      const permissions = [];
      value.forEach((permission, i) => {
        permissions.push({
          permissionKey: this.permissionsList[i].view.id,
          allowedFor: permission.view,
        });
        permissions.push({
          permissionKey: this.permissionsList[i].create.id,
          allowedFor: permission.create,
        });
        permissions.push({
          permissionKey: this.permissionsList[i].write.id,
          allowedFor: permission.write,
        });
        permissions.push({
          permissionKey: this.permissionsList[i].delete.id,
          allowedFor: permission.delete,
        });
      });

      this.propagateChange(permissions);
    });
  }

  writeValue(obj: { permissionKey: string, allowedFor: string }[]): void {
    if (obj) {
      const changes = permissionsList.map(() => {
        return {
          view: 'None',
          create: 'None',
          write: 'None',
          delete: 'None',
        };
      });
      obj.forEach(perm => {
        if (perm.permissionKey.startsWith('View')) {
          const index = this.permissionsList.findIndex(x => x.view.id === perm.permissionKey);
          changes[index].view = perm.allowedFor;
          //     this.formArray.at(index).patchValue({view: perm.allowedFor});
        } else if (perm.permissionKey.startsWith('Create')) {
          const index = this.permissionsList.findIndex(x => x.create.id === perm.permissionKey);
          changes[index].create = perm.allowedFor;
          //     this.formArray.at(index).patchValue({create: perm.allowedFor});
        } else if (perm.permissionKey.startsWith('Write')) {
          const index = this.permissionsList.findIndex(x => x.write.id === perm.permissionKey);
          changes[index].write = perm.allowedFor;
          //     this.formArray.at(index).patchValue({write: perm.allowedFor});
        } else if (perm.permissionKey.startsWith('Delete')) {
          const index = this.permissionsList.findIndex(x => x.delete.id === perm.permissionKey);
          changes[index].delete = perm.allowedFor;
          //     this.formArray.at(index).patchValue({delete: perm.allowedFor});
        }
      });
      this.formArray.patchValue(changes);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }
}

