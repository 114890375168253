import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {UserPost} from '../../shared/models/user-post';
import {PostService} from '../shared/services/post.service';
import {DOCUMENT} from '@angular/common';
import {MessageService} from '../../shared/services/message.service';

@Component({
  selector: 'app-posts-own',
  templateUrl: './posts-own.component.html',
  styleUrls: ['./posts-own.component.css']
})
export class PostsOwnComponent implements OnInit {
  isLoading = false;
  isAtEnd = false;

  posts: UserPost[] = [];

  constructor(
    private messageService: MessageService,
    private postService: PostService,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit() {
    this.loadPosts();
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    if (this.document.documentElement.scrollTop + 100 > this.document.documentElement.scrollHeight - this.document.documentElement.clientHeight) {
      this.loadPosts();
    }
  }

  loadPosts() {
    if (this.isLoading || this.isAtEnd) {
      return;
    }
    this.isLoading = true;

    this.postService.getOwnPosts(this.posts.length, 10)
      .subscribe(posts => {
        if (posts.length < 10) {
          this.isAtEnd = true;
        }
        this.posts.push(...posts);

        this.isLoading = false;
      }, error => {
        this.messageService.showMessage('Deine Posts konnten nicht geladen werden. Bitte versuche es später erneut.');
      });
  }

}
