import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {AdminFeedbackService} from '../shared/services/admin-feedback.service';
import {merge} from 'rxjs';
import {map, startWith, switchMap} from 'rxjs/operators';
import {Feedback} from '../shared/models/feedback';
import {MessageService} from '../../shared/services/message.service';

@Component({
  selector: 'app-admin-feedback',
  templateUrl: './admin-feedback.component.html',
  styleUrls: ['./admin-feedback.component.css']
})
export class AdminFeedbackComponent implements OnInit, AfterViewInit {
  displayedColumns = ['message', 'route', 'datetime', 'actions'];

  data: Feedback[] = [];
  resultsLength = 0;
  isLoading = true;

    @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  constructor(
    private feedbackService: AdminFeedbackService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit(): void {
    this.feedbackService.getCount().subscribe(count => this.resultsLength = count);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          return this.feedbackService.getFeedback(this.sort.active, this.sort.direction === 'desc', this.paginator.pageIndex);
        }),
        map(data => {
          this.isLoading = false;

          return data;
        })
      ).subscribe(data => this.data = data);
  }

  completeFeedback(item: Feedback) {
    this.feedbackService.markAsCompleted(item.id).subscribe(() => {
      item.completed = true;
      this.messageService.showMessage('Das Feedback wurde erfolgreich abgeschlossen.');
    }, () => {
      this.messageService.showMessage('Beim Abschließen des Feedbacks ist ein Fehler aufgetreten.');
    });
  }
}
