import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Message} from '../shared/model/message';
import {AppConfig} from '../../../app-config';
import {AuthenticationService} from '../../../shared/services/authentication.service';

@Component({
  selector: 'app-chat-message-item',
  templateUrl: './chat-message-item.component.html',
  styleUrls: ['./chat-message-item.component.css']
})
export class ChatMessageItemComponent implements OnInit {
  @Input() isSentByUser: boolean;

  private messageValue: Message;
  private formattedMessage: string;

  private lastDownload = 0;

  @Output() messageClick = new EventEmitter();

  constructor(
    private authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit() {
  }

  isFileImage(): boolean {
    return this.messageValue.content.file && this.messageValue.content.file.type.startsWith('image');
  }

  getFileUrl(): string {
    if (!this.messageValue.content.file) {
      return '';
    }
    return `${AppConfig.API_ENDPOINT}/chats/${this.messageValue.id}/files/${this.messageValue.content.file.id}?token=${this.authenticationService.currentUserValue.token}`;
  }

  viewFile() {
    if (Date.now() - this.lastDownload < 200) {
      return;
    }
    window.open(this.getFileUrl(), '_BLANK');
    this.lastDownload = Date.now();
  }

  downloadFile() {
    if (Date.now() - this.lastDownload < 200) {
      return;
    }
    window.open(this.getFileUrl() + '&download=1', '_BLANK');
    this.lastDownload = Date.now();
  }

  @Input()
  set message(value) {
    this.messageValue = value;
    this.formatMessageBody();
  }

  private formatMessageBody() {
    // if (this.messageValue.content.text) {
    //   let message = this.messageValue.content.text as string;
    // this.formattedMessage = message;
    // } else {
    //   this.formattedMessage = '';
    // }
  }


}
