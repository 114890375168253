import {Component, ElementRef, EventEmitter, forwardRef, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChatInputComponent),
      multi: true,
    }
  ]
})
export class ChatInputComponent implements OnInit, ControlValueAccessor {
  @ViewChild('message', {static: true}) message: ElementRef;

  @Output() send = new EventEmitter();

  private propagateChange = (_: any) => {
  };

  constructor() {
  }

  ngOnInit() {
  }

  onKeyPress(event, isUp = false) {
    this.propagateChange(this.getMessage(event.target));

    if (isUp) {
      if (event.key === 'Enter' || event.which === 13) {
        if (!event.ctrlKey && !event.shiftKey) {
          event.stopPropagation();
          this.send.emit();
        }
      }
    }
  }

  private getMessage(target): string {
    return target.innerText.trim();
  }

  writeValue(obj: any): void {
    this.message.nativeElement.innerHTML = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }
}

