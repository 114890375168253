import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Notification} from '../../../shared/models/notification';
import {AppConfig} from '../../../app-config';
import {first, map} from 'rxjs/operators';
import {SocketService} from '../../messages/shared/services/socket.service';
import {AuthenticationService} from '../../../shared/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notifications = new BehaviorSubject<Notification[]>([]);
  private newNotificationCount = new BehaviorSubject<number>(0);

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private socketService: SocketService,
  ) {
    // Empfängt neue Benachrichtigungen über Socket.io
    this.authenticationService.currentUser.subscribe(user => {
      if (user) {
        this.socketService.onNotification().subscribe(notification => {
          this.notifications.next(this.notifications.value.concat(notification));
          this.newNotificationCount.next(this.newNotificationCount.value + 1);
        });
      }
    });
  }

  getNotifications(): Observable<Notification[]> {
    return this.notifications.asObservable();
  }

  loadNotifications() {
    this.http.get<Notification[]>(`${AppConfig.API_ENDPOINT}/notifications?limit=5`)
      .pipe(first())
      .subscribe(notifications => {
        this.notifications.next(notifications);
      });
  }

  getNewNotificationCount(): Observable<number> {
    return this.newNotificationCount.asObservable();
  }

  loadNewNotificationCount() {
    this.http.get<{ count: number }>(`${AppConfig.API_ENDPOINT}/notifications/count/new`)
      .pipe(first())
      .pipe(map(count => count.count))
      .subscribe(count => this.newNotificationCount.next(count));
  }

  markNotificationAsSeen(notificationId: string) {
    this.http.patch(`${AppConfig.API_ENDPOINT}/notifications/seen/${notificationId}`, {}).subscribe(() => {
      this.loadNewNotificationCount();
    });
  }
}
