import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainComponent} from './main.component';
import {FeedComponent} from './feed/feed.component';
import {PostListComponent} from './post-list/post-list.component';
import {PostEntryComponent} from './post-list/post-entry/post-entry.component';
import {RegisterComponent} from './register/register.component';
import {LoginComponent} from './login/login.component';
import {PostDetailsComponent} from './post-details/post-details.component';
import {IconDetailsComponent} from './icon-details/icon-details.component';
import {NavbarComponent} from './navbar/navbar.component';
import {MessagesComponent} from './messages/messages.component';
import {ChatComponent} from './messages/chat/chat.component';
import {ChatMessageListComponent} from './messages/chat-message-list/chat-message-list.component';
import {ChatMessageItemComponent} from './messages/chat-message-item/chat-message-item.component';
import {ChatMessageGroupComponent} from './messages/chat-message-group/chat-message-group.component';
import {ContactDialogComponent} from './post-details/contact-dialog/contact-dialog.component';
import {SearchComponent} from './search/search.component';
import {SettingsComponent} from './settings/settings.component';
import {HomeComponent} from './home/home.component';
import {NotificationMenuComponent} from './navbar/notification-menu/notification-menu.component';
import {UserComponent} from './user/user.component';
import {PostsOwnComponent} from './posts-own/posts-own.component';
import {SkillsSelectComponent} from './settings/skills-select/skills-select.component';
import {SkillsColumnComponent} from './settings/skills-select/skills-column/skills-column.component';
import {PostCreationFormComponent} from './post-creation-form/post-creation-form.component';
import {SearchFilterComponent} from './search/search-filter/search-filter.component';
import {FilterCheckboxesComponent} from './search/search-filter/filter-checkboxes/filter-checkboxes.component';
import {ChatInputComponent} from './messages/chat-input/chat-input.component';
import {HomeLoggedInComponent} from './home/home-logged-in/home-logged-in.component';
import {DensePostListComponent} from './post-list/dense-post-list/dense-post-list.component';
import {DensePostEntryComponent} from './post-list/dense-post-list/dense-post-entry/dense-post-entry.component';
import {TimelineComponent} from './post-details/timeline/timeline.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {MaterialModule} from '../shared/material/material.module';
import {MainRoutingModule} from './main-routing.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {UploadModule} from '../upload/upload.module';
import {AdminModule} from '../admin/admin.module';
import {FooterComponent} from './footer/footer.component';
import {ContactsComponent} from './messages/contacts/contacts.component';
import {CloseDialogComponent} from './post-details/close-dialog/close-dialog.component';
import {DeleteDialogComponent} from './post-details/delete-dialog/delete-dialog.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {ChatMessageMenuComponent} from './messages/chat-message-menu/chat-message-menu.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ResetPasswordChangeComponent} from './reset-password/reset-password-change/reset-password-change.component';
import {VerifyEmailComponent} from './register/verify-email/verify-email.component';
import {ReportDialogComponent} from './post-details/report-dialog/report-dialog.component';
import {PostResponsesComponent} from './post-responses/post-responses.component';
import {WalkthroughComponent} from './walkthrough/walkthrough.component';
import {AccountSettingsComponent} from './settings/account-settings/account-settings.component';
import {SecuritySettingsComponent} from './settings/security-settings/security-settings.component';
import {EmailSettingsComponent} from './settings/email-settings/email-settings.component';
import {ProfileSettingsComponent} from './settings/profile-settings/profile-settings.component';
import {ChatHeaderComponent} from './messages/chat-header/chat-header.component';
import {ChatConversationsComponent} from './messages/chat-conversations/chat-conversations.component';
import {ChatConversationListComponent} from './messages/chat-conversation-list/chat-conversation-list.component';
import {ChatConversationItemComponent} from './messages/chat-conversation-item/chat-conversation-item.component';
import {ChatConversationRatingDialogComponent} from './messages/chat-conversation-rating-dialog/chat-conversation-rating-dialog.component';
import {StarRatingComponent} from './star-rating/star-rating.component';
import {StarRatingDisplayComponent} from './star-rating-display/star-rating-display.component';
import {ProfileHeaderComponent} from './user/profile-header/profile-header.component';
import {ProfileSkillsComponent} from './user/profile-skills/profile-skills.component';
import {ProfileRatingComponent} from './user/profile-rating/profile-rating.component';
import {PostResponseRefuseConfirmDialogComponent} from './post-responses/post-response-refuse-confirm-dialog/post-response-refuse-confirm-dialog.component';
import {PostAttachmentsComponent} from './post-details/post-attachments/post-attachments.component';
import {RemoveContactConfirmDialogComponent} from './messages/remove-contact-confirm-dialog/remove-contact-confirm-dialog.component';
import {LogoutConfirmDialogComponent} from './navbar/logout-confirm-dialog/logout-confirm-dialog.component';
import {ReportUserDialogComponent} from './user/report-user-dialog/report-user-dialog.component';
import {FeedbackDialogComponent} from './feedback-dialog/feedback-dialog.component';
import {PipeModule} from '../shared/modules/pipe.module';

@NgModule({
  declarations: [
    MainComponent,
    FeedComponent,
    PostListComponent,
    PostEntryComponent,
    RegisterComponent,
    LoginComponent,
    PostDetailsComponent,
    IconDetailsComponent,
    NavbarComponent,
    MessagesComponent,
    ChatComponent,
    ChatMessageListComponent,
    ChatMessageItemComponent,
    ChatMessageGroupComponent,
    ContactDialogComponent,
    SearchComponent,
    SettingsComponent,
    HomeComponent,
    NotificationMenuComponent,
    UserComponent,
    PostsOwnComponent,
    SkillsSelectComponent,
    SkillsColumnComponent,
    PostCreationFormComponent,
    SearchFilterComponent,
    FilterCheckboxesComponent,
    ChatInputComponent,
    HomeLoggedInComponent,
    DensePostListComponent,
    DensePostEntryComponent,
    TimelineComponent,
    FooterComponent,
    ContactsComponent,
    CloseDialogComponent,
    DeleteDialogComponent,
    ChatMessageMenuComponent,
    ResetPasswordComponent,
    ResetPasswordChangeComponent,
    VerifyEmailComponent,
    ReportDialogComponent,
    PostResponsesComponent,
    WalkthroughComponent,
    AccountSettingsComponent,
    SecuritySettingsComponent,
    EmailSettingsComponent,
    ProfileSettingsComponent,
    ChatHeaderComponent,
    ChatConversationsComponent,
    ChatConversationListComponent,
    ChatConversationItemComponent,
    ChatConversationRatingDialogComponent,
    StarRatingComponent,
    StarRatingDisplayComponent,
    ProfileHeaderComponent,
    ProfileSkillsComponent,
    ProfileRatingComponent,
    PostResponseRefuseConfirmDialogComponent,
    PostAttachmentsComponent,
    RemoveContactConfirmDialogComponent,
    LogoutConfirmDialogComponent,
    ReportUserDialogComponent,
    FeedbackDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    MainRoutingModule,
    FlexLayoutModule,
    ScrollingModule,
    UploadModule,
    AdminModule,
    OverlayModule,
    PipeModule,
  ],
  entryComponents: [
    ContactDialogComponent,
    CloseDialogComponent,
    DeleteDialogComponent,
    ReportDialogComponent,
    ChatConversationRatingDialogComponent,
    PostResponseRefuseConfirmDialogComponent,
    RemoveContactConfirmDialogComponent,
    LogoutConfirmDialogComponent,
    ReportUserDialogComponent,
  ],
  bootstrap: [MainComponent],
})
export class MainModule {
}
