import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit {
  @Input() items: TimelineEntry[];
  @Input() gradient: string;

  constructor() {
  }

  ngOnInit() {
  }

}

export class TimelineEntry {
  type: string;
  title: string;
  date: string;
}
