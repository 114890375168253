import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../services/authentication.service';
import {MessageService} from '../services/message.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private permissionLevels = ['None', 'Self', 'Group', 'Every', 'All'];

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
  ) {
  }

  private convertPermission(permission: string): { key: string, level: number } {
    const [key, level] = permission.split(':');

    return {
      key, level: this.permissionLevels.indexOf(level),
    };
  }

  private checkPermission(requiredPermissions: string[], userPermissions: string[]): boolean {
    const required = requiredPermissions.map(perm => this.convertPermission(perm));
    const user = userPermissions.map(perm => this.convertPermission(perm));

    let hasPermission = false;

    required.forEach(perm => {
      const userPerm = user.find(p => p.key === perm.key);
      if (userPerm && userPerm.level >= perm.level) {
        hasPermission = true;
      }
    });

    return hasPermission;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      // Überprüfe Nuzterberechtigung
      if (route.data.permissions && !this.checkPermission(route.data.permissions, currentUser.permissions)) {
        // Nutzer ist nicht berechtigt und wird zur Startseite weitergeleitet
        this.router.navigate(['/']);
        this.messageService.showMessage('Nicht autorisiert');
        return false;
      }

      // Nutzer ist authorisiert
      return true;
    }

    this.router.navigate(['/login'], {
      queryParams: {returnUrl: state.url}
    });
    return false;
  }
}
