import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../models/user';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {AppConfig} from '../../app-config';
import {SocketService} from '../../main/messages/shared/services/socket.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  private permanentUserData: { id: string, token: string };
  public currentUser: Observable<User>;

  constructor(
    private socketService: SocketService,
    private http: HttpClient,
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.permanentUserData = this.currentUserValue;

    if (this.currentUserValue) {
      this.socketService.initSocket();
      this.socketService.handshake(this.currentUserValue);
    }

    this.startUpdateLoop();
  }

  public get currentUserValue(): User {
    return JSON.parse(JSON.stringify(this.currentUserSubject.value));
  }

  login(username: string, password: string): Observable<any> {
    return this.http.post<any>(`${AppConfig.API_ENDPOINT}/users/auth`, {username, password})
      .pipe(map(data => {
        if (data) {
          data.profilePicture = `${AppConfig.API_ENDPOINT}/users/profile-picture/${data.profilePicture}`;

          // Speichern der Daten im Local Storage um späteres Einloggen zu vereinfachen
          localStorage.setItem('currentUser', JSON.stringify(data));

          // Login in to chat
          this.socketService.initSocket();
          this.socketService.handshake(data);

          this.currentUserSubject.next(data);
          this.permanentUserData = this.currentUserValue;

          this.startUpdateLoop();
        }

        return data;
      }));
  }

  updateUserData() {
    this.http.get<any>(`${AppConfig.API_ENDPOINT}/users/own`)
      .pipe(map(user => {
        user.profilePicture = `${AppConfig.API_ENDPOINT}/users/profile-picture/${user.profilePicture}`;
        return user;
      }))
      .subscribe(data => {
        const newData = Object.assign(this.currentUserValue, data);
        localStorage.setItem('currentUser', JSON.stringify(newData));
        this.currentUserSubject.next(newData);
      });
  }

  private startUpdateLoop() {
    if (this.currentUserValue) {
      setInterval(() => {
        this.updateUserData();
      }, 30000);
    }
  }

  logout() {
    this.socketService.disconnect();
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
