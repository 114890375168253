import {Component, OnInit} from '@angular/core';
import {Subject} from '../../shared/models/subject';
import {MessageService} from '../../shared/services/message.service';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import {AdminSubjectService} from '../shared/services/admin-subject.service';

@Component({
  selector: 'app-admin-subjects',
  templateUrl: './admin-subjects.component.html',
  styleUrls: ['./admin-subjects.component.css']
})
export class AdminSubjectsComponent implements OnInit {
  private subjects: BehaviorSubject<Subject[]> = new BehaviorSubject<Subject[]>([]);

  constructor(
    private subjectsService: AdminSubjectService,
    private messageService: MessageService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.subjectsService.getSubjects().subscribe(subjects => {
      this.next(subjects);
    }, error => {
      this.messageService.showMessage('Fächer konnten nicht geladen werden');
    });
  }

  private next(subjects: Subject[]) {
    this.subjects.next(subjects.sort((a, b) => {
      return a.displayName.localeCompare(b.displayName);
    }));
  }

}
