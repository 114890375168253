import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {MessageService} from '../../../shared/services/message.service';
import {UserSettingsService} from '../../shared/services/user-settings.service';
import {validatePassword} from '../../../shared/validators/password.validator';
import {passwordMatchValidator} from '../../../shared/validators/password-match.validator';

@Component({
  selector: 'app-security-settings',
  templateUrl: './security-settings.component.html',
  styleUrls: ['./security-settings.component.css']
})
export class SecuritySettingsComponent implements OnInit {
  passwordForm: FormGroup;
  wasPasswordChanged = false;
  passwordHide = true;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private settingsService: UserSettingsService,
  ) {
  }

  ngOnInit() {
    this.passwordForm = this.fb.group({
      currentPassword: ['', Validators.required],
      password: ['', [Validators.required, validatePassword]],
      confirmPassword: ['', Validators.required],
    }, {
      validators: passwordMatchValidator
    });
    this.passwordForm.valueChanges.subscribe(() => this.wasPasswordChanged = true);
  }

  savePassword() {
    this.wasPasswordChanged = false;

    this.settingsService.savePassword(this.passwordForm.value).subscribe(() => {
      this.authenticationService.logout();
      this.router.navigate(['/login'], {
        queryParams: {
          returnUrl: this.route.snapshot.url,
        }
      });
      this.messageService.showMessage('Dein Passwort wurde geändert. Bitte logge dich erneut ein.');
    }, error => {
      console.log(error);
      switch (error.message) {
        case 'INVALID_PASSWORD':
          this.messageService.showMessage('Das Passwort ist nicht korrekt.');
          break;
        default:
          this.messageService.showMessage('Ein Fehler ist aufgetreten. Bitte versuche es später erneut.');
          break;
      }
      this.wasPasswordChanged = true;
    });
  }
}
