import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {AbstractControl, FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {SchoolClass} from '../../../shared/models/school-class';
import {distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-drag-order-list',
  templateUrl: './drag-order-list.component.html',
  styleUrls: ['./drag-order-list.component.css']
})
export class DragOrderListComponent implements OnInit {
  private form: FormGroup;
  private itemsSubject = new BehaviorSubject<SchoolClass[]>([]);

  get itemsValue(): SchoolClass[] {
    return JSON.parse(JSON.stringify(this.itemsSubject.value));
  }

  @Input()
  set items(items: SchoolClass[]) {
    this.itemsSubject.next(items);
  }

  @Output() itemsChange = new EventEmitter();

  constructor(
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      items: this.fb.array([]),
    });

    this.itemsSubject
      .pipe(distinctUntilChanged((x, y) => {
        return JSON.stringify(x) === JSON.stringify(y);
      }))
      .subscribe(items => {
        const formArray = this.itemList;

        this.itemsChange.emit(items);

        const newFields: { [key: number]: AbstractControl } = {};
        if (items.length < formArray.length) {
          for (let i = formArray.length - 1; i >= items.length; i--) {
            formArray.removeAt(i);
          }
        } else if (items.length > formArray.length) {
          for (let i = formArray.length; i < items.length; i++) {
            const control = this.fb.control('');
            formArray.push(control);
            newFields[i] = control;
          }
        }

        formArray.patchValue(items.map(item => item.name));

        Object.entries(newFields).forEach(control => {
          control[1].valueChanges.subscribe(value => {
            const itemsValue = this.itemsValue;
            itemsValue[control[0]].name = value;
            this.itemsSubject.next(itemsValue);
          });
        });
      });
  }

  ngOnInit() {
  }

  get itemList(): FormArray {
    return (this.form.controls.items as FormArray);
  }

  drop(event) {
    const items = this.itemsValue;
    moveItemInArray(items, event.previousIndex, event.currentIndex);
    this.itemsSubject.next(items);
  }

  @Input()
  set itemCount(count: number) {
    const items = this.itemsValue;
    if (count < items.length) {
      items.splice(count, items.length - count);
    } else if (count > items.length) {
      for (let i = items.length + 1; i <= count; i++) {
        items.push({
          id: null,
          name: `Klasse ${i}`,
          orderPosition: i - 1,
          classYearId: '',
        });
      }
    }
    this.itemsSubject.next(items);
  }
}
