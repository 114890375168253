import {Component, OnInit} from '@angular/core';
import {PostService} from '../../shared/services/post.service';
import {UserPost} from '../../../shared/models/user-post';
import {PostResponseService} from '../../shared/services/post-response.service';

@Component({
  selector: 'app-home-logged-in',
  templateUrl: './home-logged-in.component.html',
  styleUrls: ['./home-logged-in.component.css']
})
export class HomeLoggedInComponent implements OnInit {
  isMobile = false;
  private mobileThreshold = 960;

  recentResponses;
  newestPosts: UserPost[];

  constructor(
    private postService: PostService,
    private postResponseService: PostResponseService,
  ) {
  }

  ngOnInit() {
    this.isMobile = window.innerWidth < this.mobileThreshold;

    this.postResponseService.getCompactResponses(5).subscribe(responses => this.recentResponses = responses);
    this.postService.getNewestPosts().subscribe(posts => this.newestPosts = posts);
  }

  onResize(event) {
    this.isMobile = event.target.innerWidth < this.mobileThreshold;
  }

}
