import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-logout-confirm-dialog',
  templateUrl: './logout-confirm-dialog.component.html',
  styleUrls: ['./logout-confirm-dialog.component.css']
})
export class LogoutConfirmDialogComponent implements OnInit {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private dialogRef: MatDialogRef<LogoutConfirmDialogComponent>,
  ) {
  }

  ngOnInit() {
  }

  logout() {
    this.authenticationService.logout();
    if (this.router.url === '/') {
      location.reload();
    } else {
      this.router.navigate(['/']);
      this.dialogRef.close();
    }
  }

}
