import {Injectable} from '@angular/core';
import {SocketService} from './socket.service';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Message} from '../model/message';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../../../app-config';
import {map} from 'rxjs/operators';
import {RawMessage} from '../model/raw-message';

@Injectable({
  providedIn: 'root'
})
export class UnreadMessagesService {
  private unreadMessages = new BehaviorSubject<Message[]>([]);

  constructor(
    private socketService: SocketService,
    private authenticationService: AuthenticationService,
    private http: HttpClient,
  ) {
  }

  init() {
    // Erhält alle ungelesenen Nachrichten vom Server
    this.http.get<Message[]>(`${AppConfig.API_ENDPOINT}/chats/messages/unseen`)
      .subscribe(messages => {
        this.unreadMessages.next(messages);
      });

    // Reagiert, wenn neue Nachrichten empfangen werden
    this.socketService.onMessage().subscribe((message: RawMessage) => {
      if (message.senderId !== this.authenticationService.currentUserValue.id) {
        // this.unreadMessages.next(this.unreadMessages.value + 1);
        const unread = this.unreadMessages.value;
        if (!unread.find(m => m.id === message.id)) {
          unread.push(message);
        }
        this.unreadMessages.next(unread);
      }
    });

    // Registriert einen Listener, um zu erfahren, wenn der Nutzer ungelesene Nachrichten gelesen hat
    this.socketService.onMessageSeen().subscribe(message => {
      const unread = this.unreadMessages.value;
      const index = unread.findIndex(m => m.id === message.id);
      if (index) {
        unread.splice(index, 1);
      }
      this.unreadMessages.next(unread);
    });
  }

  getUnreadMessageCount(): Observable<number> {
    return this.unreadMessages.pipe(map(messages => messages.length));
  }

}
