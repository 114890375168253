import {Component, OnInit} from '@angular/core';
import {WalkthroughService} from './shared/services/walkthrough.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  constructor(
    private walkthroughService: WalkthroughService,
  ) {
  }

  ngOnInit() {
  }

  isWalkthroughDisplayed() {
    return this.walkthroughService.isDisplayed();
  }

}
