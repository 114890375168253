import {Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {Message} from '../shared/model/message';
import {TemplatePortal} from '@angular/cdk/portal';
import {fromEvent, Subscription} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {User} from '../../../shared/models/user';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {SocketService} from '../shared/services/socket.service';

@Component({
  selector: 'app-chat-message-menu',
  templateUrl: './chat-message-menu.component.html',
  styleUrls: ['./chat-message-menu.component.css']
})
export class ChatMessageMenuComponent implements OnInit {
  @ViewChild('messageMenu', {static: true}) messageMenu: TemplateRef<any>;
  private overlayRef: OverlayRef | null;
  private menuSub: Subscription;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private overlay: Overlay,
    private authenticationService: AuthenticationService,
    private socketService: SocketService,
  ) {
  }

  ngOnInit() {
  }

  @Input()
  set open(value) {
    if (value) {
      this.openMenu(value.event, value.message);
    }
  }

  openMenu({x, y}: MouseEvent, message: Message) {
    this.closeMenu();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({x, y})
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    this.overlayRef.attach(new TemplatePortal(this.messageMenu, this.viewContainerRef, {
      $implicit: message,
    }));

    this.menuSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeMenu());
  }

  closeMenu() {
    this.menuSub && this.menuSub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  isByUser(sender: User) {
    return this.authenticationService.currentUserValue.id === sender.id;
  }

  delete(message: Message) {
    this.socketService.delete(message);
    this.closeMenu();
  }
}
