import {Component, Input, OnInit} from '@angular/core';
import {ProfileRating} from '../../shared/models/help-rating';

@Component({
  selector: 'app-profile-rating',
  templateUrl: './profile-rating.component.html',
  styleUrls: ['./profile-rating.component.css']
})
export class ProfileRatingComponent implements OnInit {

  @Input() rating: ProfileRating;

  constructor() {
  }

  ngOnInit() {
  }

}
