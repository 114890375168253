import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PostResponse} from '../../shared/models/post-response';

@Component({
  selector: 'app-post-response-refuse-confirm-dialog',
  templateUrl: './post-response-refuse-confirm-dialog.component.html',
  styleUrls: ['./post-response-refuse-confirm-dialog.component.css']
})
export class PostResponseRefuseConfirmDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PostResponseRefuseConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      response?: PostResponse,
    },
  ) {
  }

  ngOnInit() {
  }

}
