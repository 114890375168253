import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AdminUserService} from '../../shared/services/admin-user.service';
import {MessageService} from '../../../shared/services/message.service';
import {UserPending} from '../../shared/models/user-pending';

@Component({
  selector: 'app-admin-pending-user-confirmation-dialog',
  templateUrl: './admin-pending-user-confirmation-dialog.component.html',
  styleUrls: ['./admin-pending-user-confirmation-dialog.component.css']
})
export class AdminPendingUserConfirmationDialogComponent implements OnInit {
  user: UserPending;
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<AdminPendingUserConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      userId: string,
    },
    private userService: AdminUserService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
    this.userService.getUserPendingDetails(this.data.userId).subscribe(userPending => {
      this.user = userPending;
    });
  }

  confirmUser(reaction: boolean) {
    this.isLoading = true;
    this.userService.confirmUserPending(this.user.id, reaction).subscribe(() => {
      if (reaction) {
        this.messageService.showMessage('Der Nutzer wurde erfolgreich bestätigt.');
      } else {
        this.messageService.showMessage('Dem Nutzer wurde mitgeteilt, dass seine Registrierung nicht akzeptiert wurde.');
      }
      this.dialogRef.close(reaction);
    }, () => {
      this.isLoading = false;
      if (reaction) {
        this.messageService.showMessage('Beim Bestätigen des Nutzers ist ein Fehler aufgetreten.');
      } else {
        this.messageService.showMessage('Beim Ablehnen des Nutzers ist ein Fehler aufgetreten.');
      }
    });
  }
}
