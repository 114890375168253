import {Subject} from '../../../shared/models/subject';

export class SkillItem {
  colors?: string[];
  ability = 0;

  constructor(
    public id: string,
    public type: string,
    public name: string,
    public subject: Subject,
    public selected = false,
    public icon = 'arrow_drop_down',
  ) {
  }
}
