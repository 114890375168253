import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Message} from '../shared/model/message';
import {SocketService} from '../shared/services/socket.service';
import {Event} from '../shared/model/event';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {User} from '../../../shared/models/user';
import {UserService} from '../../shared/services/user.service';
import {ActivatedRoute} from '@angular/router';
import {ChatMessageService} from '../shared/services/chat-message.service';
import {Subscription} from 'rxjs';
import {RawMessage} from '../shared/model/raw-message';
import {UploadService} from '../../../upload/upload.service';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit, OnDestroy {
  @Input() recipient: User;

  messageFormGroup: FormGroup;

  private user: User;
  private ioConnections: Subscription[] = [];

  @ViewChild('file', {static: true}) file;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private socketService: SocketService,
    private uploadService: UploadService,
    private messageService: ChatMessageService) {
    this.user = Object.assign(this.authenticationService.currentUserValue, {
      email: undefined,
      permissions: undefined,
    });

    this.messageFormGroup = this.fb.group({
      message: '',
    });
  }

  ngOnInit() {
    this.initIoConnection();
  }

  ngOnDestroy() {
    this.ioConnections.forEach(sub => sub.unsubscribe());
  }

  private initIoConnection(): void {
    this.ioConnections.push(this.socketService.onMessage()
      .subscribe((message: RawMessage) => {
        this.messageService.updateOrAddMessage(this.recipient, message);
      }));

    this.ioConnections.push(this.socketService.onMessageSeen()
      .subscribe((message: Message) => {
        this.messageService.updateOrAddMessage(this.recipient, message);
      }));

    this.ioConnections.push(this.socketService.onMessageDeleted()
      .subscribe((message: Message) => {
        this.messageService.deleteMessage(this.recipient, message);
      }));

    this.ioConnections.push(this.socketService.onEvent(Event.CONNECT)
      .subscribe(() => {
        console.log('connected');
      }));

    this.ioConnections.push(this.socketService.onError()
      .subscribe(console.log));
  }

  public sendMessage(): void {
    const message = this.messageFormGroup.value.message;

    if (!this.recipient || !message || message.length === 0) {
      return;
    }

    const newMessage = this.messageService.sendMessage(this.recipient, {
      text: message,
    });
    this.socketService.send({
      senderId: this.user.id,
      recipientId: this.recipient.id,
      content: {
        text: newMessage.content.text as string
      },
      temporaryId: newMessage.id,
    });
    this.messageFormGroup.patchValue({message: ''});
  }

  openFileDialog() {
    this.file.nativeElement.click();
  }

  onFilesSelected() {
    const files = this.file.nativeElement.files;

    for (const file of files) {
      const fileUpload = this.uploadService.upload(file, 'chats/files');
      const newMessage = this.messageService.sendMessage(this.recipient, {}, 'FILE');

      fileUpload.result.subscribe(result => {
        this.socketService.send({
          senderId: this.user.id,
          recipientId: this.recipient.id,
          content: {
            fileId: result.id,
          },
          temporaryId: newMessage.id
        });
      });
    }
  }

}
