import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {NotificationService} from '../shared/services/notification.service';
import {UnreadMessagesService} from '../messages/shared/services/unread-messages.service';
import {ConfigService} from '../../shared/services/config.service';
import {MatDialog} from '@angular/material/dialog';
import {LogoutConfirmDialogComponent} from './logout-confirm-dialog/logout-confirm-dialog.component';
import {FeedbackDialogComponent} from '../feedback-dialog/feedback-dialog.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  title = 'Lernplattform';
  notificationCount = 0;
  messageCount = 0;
  ownProfileLink = '';

  notificationsMenuClosed;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private authenticationService: AuthenticationService,
    private configService: ConfigService,
    private notificationsService: NotificationService,
    private unreadMessagesService: UnreadMessagesService,
  ) {
    this.title = `Lernplattform ${configService.config.school.name}`;
  }

  ngOnInit() {
    this.authenticationService.currentUser.subscribe(user => {
      if (user !== null) {
        this.ownProfileLink = `/user/${this.authenticationService.currentUserValue.username}`;

        this.notificationsService.loadNewNotificationCount();
        this.notificationsService.getNewNotificationCount().subscribe(count => {
          this.notificationCount = count;
        });

        this.unreadMessagesService.init();
        this.unreadMessagesService.getUnreadMessageCount().subscribe(count => {
          this.messageCount = count;
        });
      }
    });
  }

  isLoggedIn(): boolean {
    return this.authenticationService.currentUserValue !== null;
  }

  onNotificationsMenuClosed() {
    this.notificationsMenuClosed = Date.now();
  }

  showFeedbackDialog() {
    const route = this.router.url;
    this.dialog.open(FeedbackDialogComponent, {
      width: '450px',
      data: {
        route,
      }
    });
  }

  showLogoutConfirmDialog() {
    this.dialog.open(LogoutConfirmDialogComponent);
  }
}
