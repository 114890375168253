import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MessageService} from '../../../shared/services/message.service';
import {User} from '../../../shared/models/user';
import {UserService} from '../../shared/services/user.service';

@Component({
  selector: 'app-report-user-dialog',
  templateUrl: './report-user-dialog.component.html',
  styleUrls: ['./report-user-dialog.component.css']
})
export class ReportUserDialogComponent implements OnInit {
  form: FormGroup;
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<ReportUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      user: User,
    },
    private fb: FormBuilder,
    private messageService: MessageService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      reasonType: ['', Validators.required],
      reasonText: '',
    });
  }

  report() {
    this.isLoading = true;
    const value = this.form.value;
    this.userService.reportUser(this.data.user.username, value.reasonType, value.reasonText)
      .subscribe(() => {
        this.messageService.showMessage('Vielen Dank für deine Meldung!');
        this.dialogRef.close(true);
      }, () => {
        this.messageService.showMessage('Beim Melden dieses Nutzers ist ein Fehler aufgetreten.');
      });
  }

}
