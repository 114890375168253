import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../../app-config';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  constructor(
    private http: HttpClient,
  ) {
  }

  requestReset(email: string): Observable<void> {
    return this.http.post<void>(`${AppConfig.API_ENDPOINT}/users/password-reset`, {email});
  }

  isTokenValid(token: string): Observable<boolean> {
    return this.http.get<boolean>(`${AppConfig.API_ENDPOINT}/users/password-reset/valid/${token}`);
  }

  changePassword(token: string, password: string): Observable<void> {
    return this.http.patch<void>(`${AppConfig.API_ENDPOINT}/users/password-reset/change`, {token, password});
  }

}
