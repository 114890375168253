import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Message} from '../shared/model/message';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {User} from '../../../shared/models/user';

@Component({
  selector: 'app-chat-message-group',
  templateUrl: './chat-message-group.component.html',
  styleUrls: ['./chat-message-group.component.css']
})
export class ChatMessageGroupComponent implements OnInit {
  @Input() messageGroup: {
    type: string,
    sender: User,
    recipient: User,
    messages: Message[],
    date: string,
  };
  sender: User;

  @Output() messageClick = new EventEmitter();

  constructor(private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.sender = this.messageGroup.sender;
  }

  isSentByUser(): boolean {
    return this.sender.id === this.authenticationService.currentUserValue.id;
  }

}
