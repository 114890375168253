import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {User} from '../../../shared/models/user';
import {UserService} from '../../shared/services/user.service';
import {ChatConversation} from '../../shared/services/contact.service';
import {map} from 'rxjs/operators';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {ChatConversationService} from '../shared/services/chat-conversation.service';
import {PostType} from '../../../shared/models/user-post';

@Component({
  selector: 'app-contact-posts',
  templateUrl: './chat-conversations.component.html',
  styleUrls: ['./chat-conversations.component.css']
})
export class ChatConversationsComponent implements OnInit {
  user: User;
  conversations: ChatConversation[];

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private chatConversationService: ChatConversationService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const username = params.get('username');

      this.userService.getContactByUsername(username).subscribe(user => {
        this.user = user;

        this.chatConversationService.getConversations(username)
          .pipe(map(conversations => {
            conversations.map(conversation => {
              if (conversation.post.type === PostType.REQUEST) {
                conversation.user = conversation.isHelper ? this.authenticationService.currentUserValue : this.user;
                conversation.post.user = conversation.isHelper ? this.user : this.authenticationService.currentUserValue;
              } else {
                conversation.user = !conversation.isHelper ? this.authenticationService.currentUserValue : this.user;
                conversation.post.user = !conversation.isHelper ? this.user : this.authenticationService.currentUserValue;
              }
            });
            return conversations;
          }))
          .subscribe(conversations => this.conversations = conversations);
      });
    });
  }

}
