import {Component, Input, OnInit} from '@angular/core';
import {NotificationService} from '../../shared/services/notification.service';
import {Notification} from '../../../shared/models/notification';
import {MessageService} from '../../../shared/services/message.service';
import {PostResponseService} from '../../shared/services/post-response.service';
import {MatDialog} from '@angular/material/dialog';
import {PostResponseRefuseConfirmDialogComponent} from '../../post-responses/post-response-refuse-confirm-dialog/post-response-refuse-confirm-dialog.component';

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.css']
})
export class NotificationMenuComponent implements OnInit {
  notifications: Notification[] = [];

  constructor(
    private dialog: MatDialog,
    private messageService: MessageService,
    private notificationsService: NotificationService,
    private postResponseService: PostResponseService,
  ) {
  }

  ngOnInit() {
    this.notificationsService.loadNotifications();
    this.notificationsService.getNotifications().subscribe(notifications => this.notifications = notifications);
  }

  reactToResponse(params, reaction: boolean) {
    this.postResponseService.reactToResponse(params.responseId, params.postId, reaction).subscribe(() => {
      if (reaction) {
        this.messageService.showMessage('Dieser Nutzer wurde dir als neuer Kontakt hinzugefügt!');
      } else {
        this.messageService.showMessage('Du hast diese Antwort erfolgreich abgelehnt.');
      }
      params.reaction = reaction;
    }, error => {
      switch (error.statusCode) {
        case 422:
          this.messageService.showMessage('Du hast bereits auf diese Antwort reagiert!');
          break;
        default:
          this.messageService.showMessage('Beim Reagieren auf diesen Nutzer ist leider ein Fehler aufgetreten. Bitte versuche es später erneut!');
          break;
      }
    });
  }

  showRefuseConfirmDialog(params) {
    const dialog = this.dialog.open(PostResponseRefuseConfirmDialogComponent);
    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.reactToResponse(params, false);
      }
    });
  }

  @Input()
  set close(value) {
    this.notifications.forEach(notification => {
      if (!notification.seen) {
        this.notificationsService.markNotificationAsSeen(notification.id);
      }
    });
  }

}
