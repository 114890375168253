import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../app-config';
import {HttpClient} from '@angular/common/http';
import {ContactService} from './contact.service';
import {map, tap} from 'rxjs/operators';
import {PostResponse} from '../models/post-response';
import {PostType} from '../../../shared/models/user-post';

@Injectable({
  providedIn: 'root'
})
export class PostResponseService {

  constructor(
    private http: HttpClient,
    private contactService: ContactService,
  ) {
  }

  getResponses(offset = 0, count = 10): Observable<PostResponse[]> {
    return this.http.get<PostResponse[]>(`${AppConfig.API_ENDPOINT}/users/posts/responses`, {
      params: {
        offset: offset.toString(),
        count: count.toString(),
      },
    })
      .pipe(map(responses => {
        return responses.map(response => {
          response.post.type = PostType.getByKey(response.post.type);
          response.user.profilePicture = `${AppConfig.API_ENDPOINT}/users/profile-picture/${response.user.profilePicture}`;
          return response;
        });
      }));
  }

  getCompactResponses(count: number): Observable<PostResponse[]> {
    return this.http.get<PostResponse[]>(`${AppConfig.API_ENDPOINT}/users/posts/responses/short?count=${count}`);
  }

  sendResponse(postId: string, content: string): Observable<boolean> {
    return this.http.post<boolean>(`${AppConfig.API_ENDPOINT}/posts/responses`, {
      postId, content,
    });
  }

  reactToResponse(responseId: string, postId: string, reaction: boolean): Observable<boolean> {
    return this.http.patch<boolean>(`${AppConfig.API_ENDPOINT}/posts/${postId}/responses/${responseId}/react`, {
      reaction,
    }).pipe(tap(() => {
      this.contactService.loadContacts();
    }));
  }
}
