import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RegisterService} from '../../shared/services/register.service';
import {MessageService} from '../../../shared/services/message.service';

@Component({
  selector: 'app-verify-email',
  template: '',
})
export class VerifyEmailComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private registerService: RegisterService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
    const token = this.route.snapshot.queryParams.token;
    if (!token) {
      this.router.navigate(['/']);
    }
    this.registerService.verify(token).subscribe(() => {
      this.router.navigate(['/login']);
      this.messageService.showMessage('Die E-Mail-Adresse wurde erfolgreich bestätigt.');
    }, () => {
      this.router.navigate(['/']);
      this.messageService.showMessage('Es konnte keine E-Mail-Adresse bestätigt werden.');
    });
  }

}
