import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Message, MessageContent} from '../model/message';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../../../app-config';
import {User} from '../../../../shared/models/user';
import {first, map, tap} from 'rxjs/operators';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {RawMessage} from '../model/raw-message';
import {ChatGroupType, ChatMessageGroup} from '../model/chat-message-group';

@Injectable({
  providedIn: 'root'
})
export class ChatMessageService {
  private chatMessageStreams: { [userId: string]: BehaviorSubject<Message[]> } = {}; // Enthält Nachrichten-Streams für Kontakte
  private isLoading = false;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
  ) {
    let currentUser = this.authenticationService.currentUserValue;
    authenticationService.currentUser.subscribe(user => {
      if (!user || !currentUser || currentUser.id !== user.id) {
        console.log('update');
        currentUser = user;
        this.chatMessageStreams = {};
        this.isLoading = false;
      }
    });
  }

  private checkForContact(contactId: string) {
    if (!this.chatMessageStreams[contactId]) {
      console.log('ekeleuiae', contactId);
      this.chatMessageStreams[contactId] = new BehaviorSubject<Message[]>([]);
    }
  }

  // Gibt ein Observable der Nachrichten von und an den Kontakt zurück.
  // Gliedert die Nachrichten in Gruppen
  getContactMessages(contact: User): Observable<{
    lastMessageId: string,
    messages: ChatMessageGroup[],
    unreadMessages: Message[],
  }> {
    this.checkForContact(contact.id);

    const self = this.authenticationService.currentUserValue;
    console.log(this.chatMessageStreams);
    return this.chatMessageStreams[contact.id].pipe(
      map(messages => {
        const messageGroups = [];
        let currentGroup: ChatMessageGroup;

        messages.forEach((message, index) => {
          // Erstelle eine anfängliche Gruppe oder wenn der Sender wechselt
          if (!currentGroup || currentGroup.sender.id !== message.sender.id || currentGroup.type !== message.type) {
            if (currentGroup) {
              messageGroups.push(currentGroup);
            }
            currentGroup = {
              type: message.type as ChatGroupType,
              sender: message.sender,
              recipient: message.sender.id === self.id ? contact : self,
              messages: [],
            };
          }

          // Erstelle neue Nachrichtengruppe, wenn ein neuer Tag anfängt
          if (index === 0 || !isSameDate(Date.parse(message.datetime), Date.parse(messages[index - 1].datetime))) {
            if (currentGroup.messages.length > 0) {
              messageGroups.push(currentGroup);
            }
            messageGroups.push({
              type: ChatGroupType.DATE,
              date: message.datetime,
            });
            currentGroup = {
              type: message.type as ChatGroupType,
              sender: message.sender,
              recipient: message.sender.id === self.id ? contact : self,
              messages: []
            };
          }

          currentGroup.messages.push(message);
        });

        if (currentGroup) {
          messageGroups.push(currentGroup);
        }

        return {
          lastMessageId: messages.length > 0 ? messages[0].id : undefined,
          messages: messageGroups,
          unreadMessages: messages.filter(message => message.status === 'NOT_READ' && message.sender.id === contact.id),
        };
      }));

  }

  private addSenderToMessage(contact: User, message: RawMessage): Message {
    const self = this.authenticationService.currentUserValue;
    message.sender = message.senderId === contact.id ? contact : self;
    return message;
  }

  // Lädt eine angegebene Anzahl an Nachrichten und fügt diese zum Nutzer-Nachrichten-Stream hinzu
  loadContactMessages(contact: User, count: number, prevMessageId?: string): Observable<Message[]> {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.checkForContact(contact.id);

    const params = {contactId: contact.id, count: count.toString()};
    if (prevMessageId) {
      // @ts-ignore
      params.prevId = prevMessageId;
    }
    return this.http.get<RawMessage[]>(`${AppConfig.API_ENDPOINT}/chats/messages`, {params}).pipe(
      first(),
      map(messages => messages.map(message => this.addSenderToMessage(contact, message))),
      tap(messages => {
        if (messages.length > 0) {
          this.addMessages(contact.id, messages);
        }
        this.isLoading = false;
      }));
  }

  // Fügt eine Nachricht an die zeitlich korrekte Stelle in einem bestehenden Nachrichten-Array ein
  private sortedInsert(messages: Message[], message: Message) {
    const newMessage = [message];
    if (messages.length === 0) { // Gibt neue Nachricht als Array zurück, wenn keine anderen Einträge vorhanden sind
      return newMessage;
    }

    const messageDate = Date.parse(message.datetime);
    if (messageDate < Date.parse(messages[0].datetime)) { // Nachricht ist erster Eintrag
      return newMessage.concat(...messages);
    }
    if (messageDate > Date.parse(messages[0].datetime)) { // Nachricht ist neuester Eintrag
      return messages.concat(message);
    }

    const center = Math.floor(messages.length / 2);
    // Rekursives Einfügen
    let left;
    let right;
    if (messageDate < Date.parse(messages[center].datetime)) { // Nachricht ist älter als mittlerer Eintrag
      left = this.sortedInsert(messages.slice(0, center), message);
      right = messages.slice(center, messages.length);
    } else { // Nachricht ist neuer als mittlerer Eintrag
      left = messages.slice(0, center + 1);
      right = this.sortedInsert(messages.slice(center + 1, messages.length), message);
    }
    return left.concat(...right);
  }

  // Fügt einen Array an Nachrichten in den bestehenden Nachrichten-Stream des angegeben Nutzers ein
  private addMessages(contactId: string, newMessages: Message[]) {
    const messageStream = this.chatMessageStreams[contactId];
    let messages = messageStream ? messageStream.getValue() : [];
    newMessages.forEach(message => {
      if (messages.length === 0) {
        messages.push(message);
        return;
      }

      if (messages.find(m => m.id === message.id)) {
        return;
      }

      messages = this.sortedInsert(messages, message);
    });
    messageStream.next(messages);
  }

  // Versucht die Nachricht zu aktualisieren oder fügt sie hinzu, falls sie noch nicht existiert
  updateOrAddMessage(contact: User, message: Message | RawMessage) {
    const stream = this.chatMessageStreams[contact.id];
    const messages = stream.getValue();

    const existingMessage = messages.find(m => m.id === message.id || m.id === message.temporaryId);
    console.log(existingMessage);
    if (existingMessage) { // Aktualisiert die Nachricht, wenn sie existiert
      Object.entries(message).forEach(entry => {
        existingMessage[entry[0]] = entry[1];
      });

      stream.next(messages);
    } else { // Fügt die Nachricht an der korrekten Stelle hinzu
      const messageWithSender = this.addSenderToMessage(contact, message as RawMessage);
      this.addMessages(contact.id, [messageWithSender]);
    }

  }

  sendMessage(contact: User, message: MessageContent, type = 'MESSAGE'): Message {
    this.checkForContact(contact.id);

    const pendingMessage: Message = {
      id: Date.now().toString(), // temporäre ID zur späteren Identifizierung
      sender: this.authenticationService.currentUserValue,
      content: message,
      datetime: new Date().toISOString(),
      status: 'PENDING',
      type,
    };

    const stream = this.chatMessageStreams[contact.id];
    const messages = stream.getValue();
    messages.push(pendingMessage);
    stream.next(messages);
    return pendingMessage;
  }

  deleteMessage(contact: User, message: Message) {
    this.updateOrAddMessage(contact, message);
  }
}

function isSameDate(a, b) {
  const day = 3600000 * 24;
  return Math.floor(a / day) === Math.floor(b / day);
}
