import {Component, forwardRef, OnInit} from '@angular/core';
import {ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-color-input',
  templateUrl: './color-input.component.html',
  styleUrls: ['./color-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorInputComponent),
      multi: true,
    },
  ]
})
export class ColorInputComponent implements OnInit, ControlValueAccessor {
  formGroup: FormGroup;

  private propagateChange = (_: any) => {
  };

  constructor(
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      colors: this.fb.array([]),
    });

    this.formArray.valueChanges.subscribe(value => {
      this.propagateChange(value.map(color => color.color));
    });
  }

  get formArray(): FormArray {
    return this.formGroup.controls.colors as FormArray;
  }

  addNewColor() {
    this.formArray.push(this.fb.group({
      color: [''],
    }));
  }

  removeColor(index) {
    this.formArray.removeAt(index);
  }

  getGradient(): string {
    const colors = this.formArray.value.map(color => color.color).join();

    return `linear-gradient(90deg, ${colors})`;
  }

  writeValue(obj: string[]): void {
    if (obj) {
      if (obj.length > this.formArray.length) {
        for (let i = this.formArray.length; i < obj.length; i++) {
          this.addNewColor();
        }
      } else if (obj.length < this.formArray.length) {
        for (let i = this.formArray.length - 1; i > obj.length; i--) {
          this.removeColor(i);
        }
      }

      this.formGroup.controls.colors.patchValue(obj.map(color => {
        return {color};
      }));
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

}
