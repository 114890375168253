import {Component, Input, OnInit} from '@angular/core';
import {ThemePalette} from '@angular/material/core';

@Component({
  selector: 'app-star-rating-display',
  templateUrl: './star-rating-display.component.html',
  styleUrls: ['./star-rating-display.component.css']
})
export class StarRatingDisplayComponent implements OnInit {

  @Input() count: number;
  @Input() rating: number;
  @Input() iconOff: string;
  @Input() iconOn: string;
  @Input() color: ThemePalette;
  @Input() size = 24;

  countArray = [0];

  constructor() {
  }

  ngOnInit() {
    this.countArray = [];
    for (let i = 0; i < this.count; i++) {
      this.countArray.push(i);
    }
  }

  getRatingWidth() {
    const rounded = Math.floor(this.rating);
    return rounded * this.size + (this.rating - rounded) * (5 / 6 * this.size) + (1 / 12 * this.size);
  }

}
