import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../shared/models/user';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {RemoveContactConfirmDialogComponent} from '../remove-contact-confirm-dialog/remove-contact-confirm-dialog.component';

@Component({
  selector: 'app-chat-header',
  templateUrl: './chat-header.component.html',
  styleUrls: ['./chat-header.component.css']
})
export class ChatHeaderComponent implements OnInit {
  @Input() user: User;

  isChatView = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.route.url.subscribe(url => {
      this.isChatView = url.length === 0;
    });
  }

  showContactRemoveDialog() {
    const dialogRef = this.dialog.open(RemoveContactConfirmDialogComponent, {
      width: '450px',
      data: {
        contact: this.user
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/messages']);
      }
    });
  }

}
