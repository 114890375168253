import {Injectable} from '@angular/core';
import {Subject} from '../models/subject';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppConfig} from '../../app-config';
import {SubjectTopic} from '../models/subject-topic';

@Injectable({
  providedIn: 'root'
})
export class SubjectsService {
  private subjects: Observable<Subject[]>;
  public subjectsValue: Subject[] = [];

  constructor(private http: HttpClient) {
    this.subjects = this.http.get<Subject[]>(`${AppConfig.API_ENDPOINT}/subjects`);
    this.subjects.subscribe(subjects => {
      this.subjectsValue = subjects;
    });
  }

  getSubjects(): Observable<Subject[]> {
    return this.subjects;
  }

  getSubject(id: string): Observable<Subject> {
    return this.subjects.pipe(
      map(subjects => subjects.find(subject => subject.id === id))
    );
  }

  getSubjectValue(id: string): Subject {
    return this.subjectsValue.find(subject => subject.id === id);
  }

  getSubjectTopics(subjectId: string): Observable<SubjectTopic[]> {
    return this.http.get<SubjectTopic[]>(`${AppConfig.API_ENDPOINT}/subjects/${subjectId}/topics`);
  }

}
