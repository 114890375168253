import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ChatConversation} from '../../shared/services/contact.service';
import {ChatConversationService} from '../shared/services/chat-conversation.service';
import {MessageService} from '../../../shared/services/message.service';

@Component({
  selector: 'app-chat-conversation-rating-dialog',
  templateUrl: './chat-conversation-rating-dialog.component.html',
  styleUrls: ['./chat-conversation-rating-dialog.component.css']
})
export class ChatConversationRatingDialogComponent implements OnInit {
  rating: number;
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<ChatConversationRatingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      conversation: ChatConversation,
      action: boolean,
    },
    private conversationService: ChatConversationService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
  }

  rateConversation() {
    this.isLoading = true;
    this.conversationService.reactToConversation(this.data.conversation, this.data.action, this.rating).subscribe(() => {
      this.messageService.showMessage('Vielen Dank für deine Bewertung!');
      this.dialogRef.close(true);
    }, () => {
      this.isLoading = false;
      this.messageService.showMessage('Beim Bewerten der Konversation ist ein Fehler aufgetreten.');
    });
  }

}
