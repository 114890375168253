import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {first} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../../shared/services/message.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  passwordHide = true;
  loading = false;
  submitted = false;
  returnUrl: string; // Die URL zu der nach dem Login zurückgekehrt werden soll

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    // Leitet den Nutzer zur Startseite weiter, wenn er bereits eingeloggt ist
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    // Liest die Return-URL aus den URL-Parametern
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  onSubmit() {
    if (this.loading) {
      return;
    }

    this.submitted = true;

    // Bricht Vorgang ab, wenn die Eingaben ungültig sind
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    const value = this.loginForm.value;
    this.authenticationService.login(value.username, value.password)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate([this.returnUrl]);
        },
        error => {
          switch (error.statusCode) {
            case 401:
              this.messageService.showMessage('Der Benutzername oder das Passwort ist falsch. Hast du deine Anmeldedaten vergessen? Versuche dein Passwort zurückzusetzen!');
              this.loginForm.controls.password.setErrors({wrongCredentials: true});
              break;
            case 403:
              if (error.message === 'email not verified') {
                this.messageService.showMessage('Du hast deine E-Mail-Adresse noch nicht bestätigt. Schau notfalls nochmal in dein Spam-Ordner, um Zugang zur Lernplattform zu erhalten');
              } else if (error.message === 'banned') {
                this.messageService.showMessage('Dein Nutzerkonto wurde gesperrt.');
              } else {
                this.messageService.showMessage('Dein Account ist noch nicht bestätigt. Du wirst per E-Mail benachrichtigt, sobald du die Plattform nutzen kannst.');
              }
              break;
            case 404:
              this.messageService.showMessage('Dieser Nutzer existiert nicht! Bist du dir sicher, dass du den Nutzernamen korrekt eingegeben hast?');
              break;
            default:
              this.messageService.showMessage('Beim Einloggen ist ein Fehler aufgetreten. Bitte versuche es später erneut');
              break;
          }
          this.loading = false;
        }
      );
  }

}
