import {User} from '../../../../shared/models/user';
import {Message} from './message';

export class ChatMessageGroup {
  type: ChatGroupType;
  sender: User;
  recipient: User;
  messages: Message[];
}

export class RawChatGroup extends ChatMessageGroup {
  senderId: string;
  recipientId: string;
}

export enum ChatGroupType {
  DATE = 'DATE',
}
