import {Component, OnInit} from '@angular/core';
import {User} from '../../shared/models/user';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../shared/services/user.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {
  selectedContact: User;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      if (paramMap.has('username')) {
        this.userService.getContactByUsername(paramMap.get('username')).subscribe(contact => {
          this.selectedContact = contact;
        });
      } else {
        this.selectedContact = undefined;
      }
    });
  }
}
