import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminComponent} from './admin.component';
import {AdminSubjectsComponent} from './admin-subjects/admin-subjects.component';
import {MaterialModule} from '../shared/material/material.module';
import {AdminSubjectSettingsComponent} from './admin-subjects/admin-subject-settings/admin-subject-settings.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {AdminRoutingModule} from './admin-routing.module';
import {MatRadioModule} from '@angular/material/radio';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {AdminUsersComponent} from './admin-users/admin-users.component';
import {AdminUserRolesComponent} from './admin-user-roles/admin-user-roles.component';
import {AdminUserRoleSettingsComponent} from './admin-user-roles/admin-user-role-settings/admin-user-role-settings.component';
import {AdminUsersAddComponent} from './admin-users-add/admin-users-add.component';
import {AdminPendingUsersComponent} from './admin-pending-users/admin-pending-users.component';
import {AdminPostsComponent} from './admin-posts/admin-posts.component';
import {AdminReportsComponent} from './admin-reports/admin-reports.component';
import {AdminDashboardComponent} from './admin-dashboard/admin-dashboard.component';
import {AdminSettingsComponent} from './admin-settings/admin-settings.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AdminClassesComponent} from './admin-classes/admin-classes.component';
import {AdminClassesCreateComponent} from './admin-classes/admin-classes-create/admin-classes-create.component';
import {DragOrderListComponent} from './admin-classes/admin-classes-create/drag-order-list/drag-order-list.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {AdminUserDetailsComponent} from './admin-user-details/admin-user-details.component';
import {PermissionsComponent} from './permissions/permissions.component';
import {ColorInputComponent} from './color-input/color-input.component';
import {ListInputComponent} from './list-input/list-input.component';
import {UsersAddListDialogComponent} from './admin-users-add/users-add-list-dialog/users-add-list-dialog.component';
import {AdminUserDetailsGeneralComponent} from './admin-user-details/admin-user-details-general/admin-user-details-general.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {AdminUserDeletePostsDialogComponent} from './admin-user-details/admin-user-delete-posts-dialog/admin-user-delete-posts-dialog.component';
import {AdminPendingUserConfirmationDialogComponent} from './admin-pending-users/admin-pending-user-confirmation-dialog/admin-pending-user-confirmation-dialog.component';
import {AdminUserBanDialogComponent} from './admin-user-details/admin-user-ban-dialog/admin-user-ban-dialog.component';
import {AdminUserDeleteDialogComponent} from './admin-user-details/admin-user-delete-dialog/admin-user-delete-dialog.component';
import {AdminPendingUserDeleteDialogComponent} from './admin-pending-users/admin-pending-user-delete-dialog/admin-pending-user-delete-dialog.component';
import {AdminPostDetailsComponent} from './admin-post-details/admin-post-details.component';
import {AdminPostDeleteDialogComponent} from './admin-post-details/admin-post-delete-dialog/admin-post-delete-dialog.component';
import {AdminUserUnbanDialogComponent} from './admin-user-details/admin-user-unban-dialog/admin-user-unban-dialog.component';
import {AdminUserActivateDialogComponent} from './admin-user-details/admin-user-activate-dialog/admin-user-activate-dialog.component';
import {AdminFeedbackComponent} from './admin-feedback/admin-feedback.component';
import {AdminUserDetailsPermissionsComponent} from './admin-user-details/admin-user-details-permissions/admin-user-details-permissions.component';
import {PipeModule} from '../shared/modules/pipe.module';

@NgModule({
  entryComponents: [
    UsersAddListDialogComponent,
    AdminUserDeletePostsDialogComponent,
    AdminUserActivateDialogComponent,
    AdminUserBanDialogComponent,
    AdminUserUnbanDialogComponent,
    AdminUserDeleteDialogComponent,
    AdminPendingUserConfirmationDialogComponent,
    AdminPendingUserDeleteDialogComponent,
    AdminPostDeleteDialogComponent,
  ],
  declarations: [
    AdminComponent,
    AdminSubjectsComponent,
    AdminSubjectSettingsComponent,
    AdminUsersComponent,
    AdminUserRolesComponent,
    AdminUserRoleSettingsComponent,
    AdminUsersAddComponent,
    AdminPendingUsersComponent,
    AdminPostsComponent,
    AdminReportsComponent,
    AdminDashboardComponent,
    AdminSettingsComponent,
    AdminClassesComponent,
    AdminClassesCreateComponent,
    DragOrderListComponent,
    AdminUserDetailsComponent,
    PermissionsComponent,
    ColorInputComponent,
    ListInputComponent,
    UsersAddListDialogComponent,
    AdminUserDetailsGeneralComponent,
    AdminUserDeletePostsDialogComponent,
    AdminPendingUserConfirmationDialogComponent,
    AdminUserBanDialogComponent,
    AdminUserDeleteDialogComponent,
    AdminPendingUserDeleteDialogComponent,
    AdminPostDetailsComponent,
    AdminPostDeleteDialogComponent,
    AdminUserUnbanDialogComponent,
    AdminUserActivateDialogComponent,
    AdminFeedbackComponent,
    AdminUserDetailsPermissionsComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AdminRoutingModule,
    MatSidenavModule,
    MatTableModule,
    MatSortModule,
    FlexLayoutModule,
    MatRadioModule,
    DragDropModule,
    MatPaginatorModule,
    PipeModule,
  ],
  bootstrap: [AdminComponent],
})
export class AdminModule {
}
