import {Component, forwardRef, OnInit} from '@angular/core';
import {ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SubjectTopic} from '../../shared/models/subject-topic';

@Component({
  selector: 'app-list-input',
  templateUrl: './list-input.component.html',
  styleUrls: ['./list-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ListInputComponent),
      multi: true,
    },
  ]
})
export class ListInputComponent implements OnInit, ControlValueAccessor {
  formGroup: FormGroup;

  private value = [];
  private propagateChange = (_: any) => {
  };

  constructor(
    private fb: FormBuilder,
  ) {
  }

  get formArray(): FormArray {
    return this.formGroup.controls.items as FormArray;
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      items: this.fb.array([]),
    });

    this.formGroup.controls.items.valueChanges.subscribe(value => {
      this.value.forEach((entry, index) => Object.assign(entry, value[index]));
      this.propagateChange(this.value);
    });
  }

  addItem() {
    this.formArray.push(this.fb.group({
      name: this.fb.control(''),
    }));
    this.value.push({});
  }

  removeItem(index: number) {
    this.formArray.removeAt(index);
    this.value.splice(index, 1);
  }

  writeValue(obj: SubjectTopic[]): void {
    if (obj) {
      if (obj.length > this.formArray.length) {
        for (let i = this.formArray.length; i < obj.length; i++) {
          this.addItem();
          this.value[i].id = obj[i].id;
        }
      } else if (obj.length < this.formArray.length) {
        for (let i = this.formArray.length - 1; i > obj.length; i--) {
          this.removeItem(i);
        }
      }

      this.formGroup.controls.items.patchValue(obj);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

}
