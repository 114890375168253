import {Component, OnInit} from '@angular/core';
import {User} from '../../../shared/models/user';
import {MessageService} from '../../../shared/services/message.service';
import {ContactService} from '../../shared/services/contact.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
  contacts: User[];

  constructor(
    private router: Router,
    private contactService: ContactService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
    this.contactService.getContacts().subscribe(contacts => {
      this.contacts = contacts;
    }, error => {
      this.messageService.showMessage('Kontakte konnten nicht geladen werden. Bitte versuche es später erneut!');
    });
  }
}
