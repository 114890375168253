import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {FeedService} from '../shared/services/feed.service';
import {UserPost} from '../../shared/models/user-post';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.css']
})
export class FeedComponent implements OnInit {
  isLoading = false;

  feed: UserPost[] = [];

  constructor(
    private feedService: FeedService,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit() {
    // Erhält Feed-Observable und aktualisiert Posts, sobald Feed aktualisiert wird
    this.feedService.getFeed()
      .subscribe(posts => {
        this.feed = posts;
        this.isLoading = false;

        if (posts.length === 0) {
          this.getFeed();
        }
      });
  }

  // Ruft Feed-Laden auf, sobald nach unten gescrollt wird
  @HostListener('window:scroll')
  onWindowScroll() {
    if (this.document.documentElement.scrollTop + 100 > this.document.documentElement.scrollHeight - this.document.documentElement.clientHeight) {
      this.getFeed();
    }
  }

  // Lädt weitere Feed-Posts
  getFeed(): void {
    if (this.isLoading || this.feedService.isAtEnd()) {
      return;
    }
    this.isLoading = true;

    this.feedService.loadFeed(this.feed.length, 10);
  }

}
