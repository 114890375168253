import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SkillItem} from '../skill-item';

@Component({
  selector: 'app-skills-column',
  templateUrl: './skills-column.component.html',
  styleUrls: ['./skills-column.component.css']
})
export class SkillsColumnComponent {
  @Input() items: SkillItem[];
  @Input() background?: string;
  @Input() displaySubject = false;
  @Output() itemClicked = new EventEmitter<{
    index: number,
    item: SkillItem,
  }>();
  @Output() level = new EventEmitter<{
    itemId: string,
    ability: number,
  }>();

  constructor() {
  }

  formatSliderLabel(value: number): string {
    const labels = [
      'Noch nicht verstanden',
      'Benötige Hilfe',
      'Ausbaufähig',
      'Mittel',
      'Gut',
      'Sehr gut',
      'Ausgezeichnet',
    ];
    return labels[value - 1];
  }
}
