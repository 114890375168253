import {Component, Input, OnInit} from '@angular/core';
import {UserRole} from '../../shared/models/user-role';
import {Permission} from '../../shared/models/permission';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AdminUserRoleService} from '../../shared/services/admin-user-role.service';
import {AdminUserService} from '../../shared/services/admin-user.service';
import {User} from '../../../shared/models/user';
import {MessageService} from '../../../shared/services/message.service';

@Component({
  selector: 'app-admin-user-details-permissions',
  templateUrl: './admin-user-details-permissions.component.html',
  styleUrls: ['./admin-user-details-permissions.component.css']
})
export class AdminUserDetailsPermissionsComponent implements OnInit {
  @Input() user: User;

  initialRole: string;
  initialPermissions: Permission[];

  roles: UserRole[] = [];
  individualPermissions: Permission[] = [];
  rolePermissions: Permission[] = [];
  permissionsFormGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private roleService: AdminUserRoleService,
    private userService: AdminUserService,
  ) {
  }

  ngOnInit(): void {
    this.initialRole = this.user.role.id;

    this.getUserPermissions();
    this.getRoleOptions();

    this.permissionsFormGroup = this.fb.group({
      role: ['', Validators.required],
      permissions: [[]]
    });

    this.permissionsFormGroup.controls.role.valueChanges.subscribe((roleId: string) => {
      this.roleService.getDetails(roleId).subscribe(role => {
        this.rolePermissions = role.permissions;
        this.updatePermissions();
      });
    });

    this.permissionsFormGroup.controls.permissions.valueChanges.subscribe((permissions: Permission[]) => {
      this.individualPermissions = [];
      permissions.forEach(permission => {
        const rolePermission = this.rolePermissions.find(perm => perm.permissionKey === permission.permissionKey);
        if (!rolePermission) {
          if (permission.allowedFor !== 'None') {
            this.individualPermissions.push(permission);
          }
          return;
        }
        if (rolePermission.allowedFor !== permission.allowedFor) {
          this.individualPermissions.push(permission);
        }
      });
    });
  }

  mergePermissions(): Permission[] {
    const permissions = JSON.parse(JSON.stringify(this.rolePermissions));

    this.individualPermissions.forEach(permission => {
      const match = permissions.find(perm => perm.permissionKey === permission.permissionKey);
      if (match) {
        match.allowedFor = permission.allowedFor;
      } else {
        permissions.push(permission);
      }
    });
    return permissions;
  }

  getUserPermissions() {
    this.userService.getUserPermissions(this.user.id).subscribe(permissions => {
      this.rolePermissions = permissions.role;
      this.individualPermissions = permissions.individual;
      this.initialPermissions = this.individualPermissions;

      this.updatePermissions();
    });
  }

  updatePermissions() {
    this.permissionsFormGroup.patchValue({
      permissions: this.mergePermissions(),
    });
  }

  getRoleOptions() {
    this.roleService.getAll().subscribe(roles => {
      this.roles = roles;
      this.permissionsFormGroup.patchValue({
        role: this.user.role.id,
      });
    });
  }

  submitPermissions() {
    const role = this.permissionsFormGroup.value.role;
    const data = {
      roleId: role,
      permissions: this.individualPermissions.map(perm => `${perm.permissionKey}:${perm.allowedFor}`),
    };
    this.userService.update(this.user.id, data).subscribe(() => {
      this.initialRole = role;
      this.initialPermissions = this.individualPermissions;
      this.messageService.showMessage('Die Berechtigungen wurden erfolgreich gespeichert.');
    }, () => {
      this.messageService.showMessage('Beim Speichern der Berechtigungen ist ein Fehler aufgetreten.');
    });
  }
}
