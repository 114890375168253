import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MessageService} from '../../../shared/services/message.service';
import {PostType, UserPost} from '../../../shared/models/user-post';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PostResponseService} from '../../shared/services/post-response.service';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.css']
})
export class ContactDialogComponent implements OnInit {
  form: FormGroup;
  post: UserPost;
  labels;
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<ContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      post: UserPost,
    },
    private postResponseService: PostResponseService,
    private messageService: MessageService,
    private fb: FormBuilder,
  ) {
    this.post = data.post;

    switch (this.post.type) {
      case PostType.REQUEST:
        this.labels = {
          lbl_title: 'Hilfe anbieten',
          lbl_description: 'Willst du dem Anfragesteller nun deine Hilfe anbieten?',
          lbl_content: 'Wie kannst du behilflich sein?',
          hnt_content: 'Kurze Zusammenfassung deiner Fähigkeiten',
          lbl_action_btn: 'Hilfe anbieten',
          success: 'Du hast dem Nutzer deine Hilfe angeboten!',
        };
        break;
      case PostType.OFFER:
        this.labels = {
          lbl_title: 'Hilfe in Anspruch nehmen',
          lbl_description: 'Möchtest du die Hilfe des Nutzers nun entgegennehmen?',
          lbl_content: 'Wieso möchtest du die Hilfe des Nutzers?',
          hnt_content: 'Kurze Zusammenfassung deines Problems',
          lbl_action_btn: 'Hilfe anfordern',
          success: 'Du hast den Nutzer um Hilfe gebeten!'
        };
        break;
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      content: this.fb.control('', [Validators.required]),
    });
  }

  respond() {
    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.postResponseService.sendResponse(this.data.post.id, this.form.value.content.trim())
      .subscribe(result => {
        this.dialogRef.close(true);
        this.messageService.showMessage(this.labels.success);
      }, error => {
        this.isLoading = false;
        switch (error.statusCode) {
          case 404:
            this.messageService.showMessage('Dieser Post wurde nicht gefunden. Womöglich wurde er in der Zwischenzeit gelöscht.');
            break;
          case 422:
            this.messageService.showMessage('Du hast bereits deine Hilfe angeboten.');
            break;
          default:
            this.messageService.showMessage('Leider ist ein Fehler aufgetreten. Bitte versuche es später erneut');
            break;
        }
      });
  }

}
