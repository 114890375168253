import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {WalkthroughService} from '../shared/services/walkthrough.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-walkthrough',
  templateUrl: './walkthrough.component.html',
  styleUrls: ['./walkthrough.component.css']
})
export class WalkthroughComponent implements OnInit, AfterViewInit {
  currentStep;
  previousHighlightedElement;

  @ViewChild('content', {static: false}) content: ElementRef;

  constructor(
    private router: Router,
    private walkthroughService: WalkthroughService,
  ) {
  }

  ngOnInit() {
    this.currentStep = this.walkthroughService.currentStep;
  }

  ngAfterViewInit(): void {
    this.updateContent();
  }

  isDisplayed() {
    return this.walkthroughService.isDisplayed();
  }

  isFirst() {
    return this.walkthroughService.isFirst();
  }

  isLast() {
    return !this.walkthroughService.hasNextStep();
  }

  previous() {
    this.walkthroughService.previous();
    this.currentStep = this.walkthroughService.currentStep;
    this.updateContent();
  }

  next() {
    this.walkthroughService.next();
    if (this.walkthroughService.isDisplayed()) {
      this.currentStep = this.walkthroughService.currentStep;
      this.updateContent();
    }
  }

  updateContent() {
    this.router.navigate(['/']);
    let left: string | number = 'initial';
    let top: string | number = 'initial';
    let transform = 'none';

    // @ts-ignore
    Node.prototype.getOffset = function() {
      const offset = {
        left: this.offsetLeft,
        top: this.offsetTop,
      };
      if (this.offsetParent) {
        const parent = this.offsetParent.getOffset();
        offset.left += parent.left;
        offset.top += parent.top;
      }
      return offset;
    };

    if (this.previousHighlightedElement) {
      this.previousHighlightedElement.remove();
    }

    if (this.currentStep.selector) {
      const original = document.getElementById(this.currentStep.selector);
      const element: HTMLElement = document.getElementById('walkthrough').appendChild(original.cloneNode(true)) as HTMLElement;
      // @ts-ignore
      const offset = original.getOffset();
      element.style.margin = '0';
      element.style.boxSizing = 'border-box';
      element.style.boxShadow = '0 0 15px #fff';
      element.style.background = this.currentStep.background;
      element.style.position = 'absolute';
      element.style.display = 'block';
      element.style.left = offset.left + 'px';
      element.style.top = offset.top + 'px';
      element.style.width = original.clientWidth + 'px';
      element.style.height = original.clientHeight + 'px';
      this.previousHighlightedElement = element;

      switch (this.currentStep.direction) {
        case 'right':
          left = offset.left + element.offsetWidth + 20;
          top = offset.top + 'px';
          break;
        case 'left':
          left = offset.left - this.content.nativeElement.clientWidth - 20;
          top = offset.top + 'px';
          break;
        case 'bottom':
          top = offset.top + element.offsetHeight + 20 + 'px';
          left = offset.left + .5 * element.offsetWidth;
          break;
        case 'top':
          top = offset.top - this.content.nativeElement.clientHeight - 20 + 'px';
          left = offset.left + .5 * element.offsetWidth;
          break;
      }

      if (typeof left === 'number') {
        left = Math.min(Math.max(left, 20), document.body.clientWidth - 320) + 'px';
      }
    } else {
      left = '50%';
      top = '50%';
      transform = 'translate(-50%, -50%)';
    }

    Object.assign(this.content.nativeElement.style, {
      left, top, transform
    });
  }

  close() {
    this.walkthroughService.complete();
  }

}

