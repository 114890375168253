import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../../shared/models/user';
import {AdminUserService} from '../../shared/services/admin-user.service';
import {MessageService} from '../../../shared/services/message.service';

@Component({
  selector: 'app-admin-user-ban-dialog',
  templateUrl: './admin-user-ban-dialog.component.html',
  styleUrls: ['./admin-user-ban-dialog.component.css']
})
export class AdminUserBanDialogComponent implements OnInit {
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<AdminUserBanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      user: User,
    },
    private userService: AdminUserService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
  }

  banUser() {
    this.isLoading = true;

    this.userService.banUser(this.data.user.id, true).subscribe(() => {
      this.messageService.showMessage('Der Nutzer wurde gesperrt.');
      this.dialogRef.close(true);
    }, () => {
      this.isLoading = false;
      this.messageService.showMessage('Beim Sperren des Nutzers ist leider ein Fehler aufgetreten.');
    });
  }

}
