import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../shared/models/user';
import {AdminUserService} from '../shared/services/admin-user.service';
import {FormBuilder} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {AdminUserDeletePostsDialogComponent} from './admin-user-delete-posts-dialog/admin-user-delete-posts-dialog.component';
import {AdminPostService} from '../shared/services/admin-post.service';
import {UserPost} from '../../shared/models/user-post';
import {AdminUserBanDialogComponent} from './admin-user-ban-dialog/admin-user-ban-dialog.component';
import {AdminUserDeleteDialogComponent} from './admin-user-delete-dialog/admin-user-delete-dialog.component';
import {AdminUserActivateDialogComponent} from './admin-user-activate-dialog/admin-user-activate-dialog.component';
import {AdminUserUnbanDialogComponent} from './admin-user-unban-dialog/admin-user-unban-dialog.component';
import {MessageService} from '../../shared/services/message.service';

@Component({
  selector: 'app-admin-user-details',
  templateUrl: './admin-user-details.component.html',
  styleUrls: ['./admin-user-details.component.css']
})
export class AdminUserDetailsComponent implements OnInit {
  user: User;
  posts: UserPost[];

  expandPosts = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private messageService: MessageService,
    private postService: AdminPostService,
    private userService: AdminUserService,
  ) {
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.userService.getUserDetails(id).subscribe(user => {
      this.user = user;
    });

    this.postService.getPostsByUser(id).subscribe(posts => this.posts = posts);
  }

  showActivateDialog() {
    const ref = this.dialog.open(AdminUserActivateDialogComponent, {
      width: '450px',
      data: {
        user: this.user,
      }
    });
    ref.afterClosed().subscribe(result => {
      if (result) {
        this.user.status = 'ACTIVE';
      }
    });
  }

  showUnbanDialog() {
    const ref = this.dialog.open(AdminUserUnbanDialogComponent, {
      width: '450px',
      data: {
        user: this.user,
      }
    });
    ref.afterClosed().subscribe(result => {
      if (result) {
        this.user.status = 'ACTIVE';
      }
    });
  }

  showDeletePostsDialog() {
    const ref = this.dialog.open(AdminUserDeletePostsDialogComponent, {
      width: '450px',
      data: {
        user: this.user,
      }
    });
    ref.afterClosed().subscribe(result => {
      if (result) {
        this.posts = [];
      }
    });
  }

  showBanDialog() {
    const ref = this.dialog.open(AdminUserBanDialogComponent, {
      width: '450px',
      data: {
        user: this.user,
      }
    });
    ref.afterClosed().subscribe(result => {
      if (result) {
        this.user.status = 'BANNED';
      }
    });
  }

  showDeleteDialog() {
    const ref = this.dialog.open(AdminUserDeleteDialogComponent, {
      width: '450px',
      data: {
        user: this.user,
      }
    });
    ref.afterClosed().subscribe(value => {
      if (value) {
        this.router.navigate(['/admin/users']);
      }
    });
  }

}
