import {AbstractControl} from '@angular/forms';

export function validatePassword(control: AbstractControl) {
  if (control.value.length === 0) {
    return null;
  }

  const errors: { [key: string]: boolean } = {};
  if (control.value.length < 8) {
    errors.passwordTooShort = true;
  }

  if (!control.value.match(/[a-zäöü]/g)) {
    errors.passwordLowercase = true;
  }

  if (!control.value.match(/[A-ZÄÖÜ]/g)) {
    errors.passwordUppercase = true;
  }

  if (!control.value.match(/[0-9]/g)) {
    errors.passwordDigit = true;
  }

  if (!control.value.match(/[^a-zäöüA-ZÄÖÜ0-9]/g)) {
    errors.passwordSpecialChar = true;
  }

  return Object.keys(errors).length > 0 ? errors : null;
}
