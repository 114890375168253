import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AdminClassService} from '../../shared/services/admin-class.service';
import {MessageService} from '../../../shared/services/message.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SchoolClass} from '../../shared/models/school-class';
import {MatSlider} from '@angular/material/slider';

@Component({
  selector: 'app-admin-classes-create',
  templateUrl: './admin-classes-create.component.html',
  styleUrls: ['./admin-classes-create.component.css']
})
export class AdminClassesCreateComponent implements OnInit {
  form: FormGroup;
  classes: SchoolClass[] = [];
  classesValue: SchoolClass[] = [];

  @ViewChild('count', {static: true}) countSlider: MatSlider;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private classesService: AdminClassService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: this.fb.control(''),
    });

    const id = this.route.snapshot.paramMap.get('classId');
    if (id) {
      this.classesService.getClass(id).subscribe(schoolClass => {
        this.form.patchValue(schoolClass);
        this.classes = this.classesValue = schoolClass.classes;
        this.countSlider.writeValue(schoolClass.classes.length);
      });
    }
  }

  submit() {
    const id = this.route.snapshot.paramMap.get('classId');
    if (id) {
      this.classesService.updateClassYear({
        id,
        name: this.form.value.name,
        classes: this.classesValue,
      }).subscribe(() => {
        this.messageService.showMessage('Die Klassenstufe wurde erfolgreich aktualisiert');
        this.router.navigate(['/admin/classes']);
      });
    } else {
      this.classesService.createClassYear({
        name: this.form.value.name,
        classes: this.classesValue,
      }).subscribe(classYear => {
        this.messageService.showMessage(`Die Klassenstufe ${classYear.name} wurde erfolgreich erstellt`);
        this.router.navigate(['/classes']);
      });
    }
  }
}
