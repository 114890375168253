import {Injectable} from '@angular/core';

import * as socketIo from 'socket.io-client';
import {Message} from '../model/message';
import {Observable} from 'rxjs';
import {Event} from '../model/event';
import {User} from '../../../../shared/models/user';
import {AppConfig} from '../../../../app-config';
import {Notification} from '../../../../shared/models/notification';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket;

  public initSocket(force = false): void {
    if (!this.socket || force) {
      this.socket = socketIo(AppConfig.CHAT_ENDPOINT);
    }
  }

  public handshake(user: User) {
    this.socket.on('connect', () => {
      this.socket.emit('init', user);
    });
  }

  public send(message: { senderId: string, recipientId: string, content: { text?: string, fileId?: string }, temporaryId: string }) {
    this.socket.emit('message', message);
  }

  public markAsRead(message: Message) {
    this.socket.emit('message.seen', {
      messageId: message.id,
    });
  }

  public delete(message: Message) {
    this.socket.emit('message.delete', {
      messageId: message.id,
    });
  }

  public onMessage(): Observable<Message> {
    return new Observable<Message>(observer => {
      this.socket.on('message', (data: Message) => observer.next(data));
    });
  }

  public onMessageSeen(): Observable<Message> {
    return new Observable<Message>(observer => {
      this.socket.on('message.seen', (data: Message) => observer.next(data));
    });
  }

  public onMessageDeleted(): Observable<Message> {
    return new Observable<Message>(observer => {
      this.socket.on('message.delete', (data: Message) => observer.next(data));
    });
  }

  public onNotification(): Observable<Notification> {
    return new Observable<Notification>(observer => {
      this.socket.on('notification', (data: Notification) => observer.next(data));
    });
  }

  public onContactUpdate(): Observable<void> {
    return new Observable<void>(observer => {
      this.socket.on('contactUpdate', (data: void) => observer.next(data));
    });
  }

  public onEvent(event: Event): Observable<any> {
    return new Observable<Event>(observer => {
      this.socket.on(event, () => observer.next());
    });
  }

  public onError(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('error', () => {
        observer.next();
      });
    });
  }

  public disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
