import {Component, OnInit} from '@angular/core';
import {User} from '../../shared/models/user';
import {UserService} from '../shared/services/user.service';
import {ActivatedRoute} from '@angular/router';
import {ProfileRating} from '../shared/models/help-rating';
import {UserProfileSkill} from '../shared/models/user-skill';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  user: User;
  skills: UserProfileSkill[];
  rating: ProfileRating;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const username = params.get('username');

      this.userService.getProfile(username)
        .subscribe(profile => {
          this.user = profile;
        }, error => {
        });

      this.userService.getProfileSkills(username)
        .subscribe(skills => {
          this.skills = skills;
        });

      this.userService.getProfileRating(username)
        .subscribe(rating => {
          this.rating = rating;
        });
    });
  }

}
