import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchFilterComponent),
      multi: true,
    }
  ]
})
export class SearchFilterComponent implements OnInit, ControlValueAccessor {
  @Input() filter: { id: string, name: string, type: string, items: BehaviorSubject<any[]> };

  form: FormGroup;

  private propagateChange = (_: any) => {
  };

  constructor(
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      checkboxes: [],
    });

    this.form.valueChanges.subscribe(value => {
      this.propagateChange(value.checkboxes);
    });
  }

  writeValue(obj: any): void {
    this.form.patchValue({checkboxes: obj});
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

}
