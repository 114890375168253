import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MessageService} from '../../../shared/services/message.service';
import {AdminPostService} from '../../shared/services/admin-post.service';
import {UserPost} from '../../../shared/models/user-post';

@Component({
  selector: 'app-admin-post-delete-dialog',
  templateUrl: './admin-post-delete-dialog.component.html',
  styleUrls: ['./admin-post-delete-dialog.component.css']
})
export class AdminPostDeleteDialogComponent implements OnInit {
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<AdminPostDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      post: UserPost,
    },
    private postService: AdminPostService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
  }

  deletePost() {
    this.isLoading = true;
    this.postService.deleteById(this.data.post.id).subscribe(() => {
      this.messageService.showMessage('Der Post wurde endgültig gelöscht.');
      this.dialogRef.close(true);
    }, () => {
      this.isLoading = false;
      this.messageService.showMessage('Beim Löschen dieses Posts ist leider ein Fehler aufgetreten.');
    });
  }

}
