import {Pipe, PipeTransform} from '@angular/core';

const gibibyte = 10737420000;
const mebibyte = 1048576;
const kibibyte = 1024;

@Pipe({
  name: 'bytes'
})
export class BytesPipe implements PipeTransform {

  transform(value: number, args?: any): any {
    if (value >= mebibyte) {
      return round(value / mebibyte) + ' MB';
    } else if (value > kibibyte) {
      return round(value / kibibyte) + ' kB';
    }
    return value + ' B';
  }

}

function round(value: number, decimalPlaces = 1): number {
  const factor = 10 ** decimalPlaces;
  return Math.round(value * factor) / factor;
}
